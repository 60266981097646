import React from 'react'
import { Redirect } from 'react-router-dom'
import '../../Form.css'

import ReCAPTCHA from "react-google-recaptcha";
import { utilsPatternStudentNumber, utilsPatternMail, utilsPatternPostalCode, utilsAddressLookup, memberCreateRecaptcha, memberTemplateDelete } from '../../Requests'
import { MemberRecaptchaCreateInput, MemberTemplateContent, DateTemplate, Message } from '../../ApiModels'
import { isMessage, toNumberOrUndefined } from '../../Helper'
import { RECAPTCHA_SITEKEY } from '../../Global';

interface RegisterProps {
    memberTemplate: {
        id: string
        memberTemplate: MemberTemplateContent
    } | undefined
}

interface RegisterState {
    firstName: string
    middleName: string
    lastName: string

    addressCity: string
    addressHouseNumber: number | undefined
    addressHouseNumberLetter: string | undefined
    addressHouseNumberAddition: string | undefined
    addressPostalCode: string
    addressPostalCodePatternValid: boolean
    addressStreetName: string

    studentNumber: string | undefined
    studentNumberPatternValid: boolean

    dateOfBirthDay: number | undefined
    dateOfBirthMonth: number | undefined
    dateOfBirthYear: number | undefined

    showExternal: boolean

    mail: string
    mailPatternValid: boolean
    password: string
    passwordRepeat: string

    recaptchaResponse: string

    render: boolean
    registering: boolean
    registered: boolean
    errorMessage: string | null
}

export default class Register extends React.Component<RegisterProps, RegisterState> {
    constructor(props: RegisterProps) {
        super(props)
        this.state = {
            firstName: "",
            middleName: "",
            lastName: "",

            addressCity: "",
            addressHouseNumber: undefined,
            addressHouseNumberLetter: undefined,
            addressHouseNumberAddition: undefined,
            addressPostalCode: "",
            addressPostalCodePatternValid: false,
            addressStreetName: "",

            studentNumber: undefined,
            studentNumberPatternValid: false,

            dateOfBirthDay: undefined,
            dateOfBirthMonth: undefined,
            dateOfBirthYear: undefined,

            showExternal: false,

            mail: "",
            mailPatternValid: false,
            password: "",
            passwordRepeat: "",

            recaptchaResponse: "",

            render: false,
            registering: false,
            registered: false,
            errorMessage: null
        }

        this.updatePostalCode = this.updatePostalCode.bind(this)
        this.updateMail = this.updateMail.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    recaptcha: ReCAPTCHA | null = null

    async componentDidMount() {
        if (this.props.memberTemplate !== undefined) {
            const memberTemplate = this.props.memberTemplate.memberTemplate
            const dateOfBirth: DateTemplate | null = memberTemplate.dateOfBirth

            const year = dateOfBirth !== null ? dateOfBirth.year : undefined
            const month = dateOfBirth !== null ? dateOfBirth.month : undefined
            const day = dateOfBirth !== null ? dateOfBirth.day : undefined

            const houseNumber = memberTemplate.houseNumber !== null ? memberTemplate.houseNumber : undefined
            const houseNumberLetter = memberTemplate.houseNumberLetter !== null ? memberTemplate.houseNumberLetter : undefined
            const houseNumberAddition = memberTemplate.houseNumberAddition !== null ? memberTemplate.houseNumberAddition : undefined

            await this.setState({
                firstName: memberTemplate.firstName,
                middleName: memberTemplate.middleName !== null ? memberTemplate.middleName : "",
                lastName: memberTemplate.lastName,
                dateOfBirthYear: year,
                dateOfBirthMonth: month,
                dateOfBirthDay: day,
                addressHouseNumber: houseNumber,
                addressHouseNumberLetter: houseNumberLetter,
                addressHouseNumberAddition: houseNumberAddition,
                studentNumber: memberTemplate.studentNumber
            })

            if (memberTemplate.postalCode !== null) {
                this.setState({ addressPostalCode: memberTemplate.postalCode })
                this.updatePostalCode(memberTemplate.postalCode)
            }

            this.updateStudentNumber(memberTemplate.studentNumber)

            if (memberTemplate.mail !== null) {
                this.setState({ mail: memberTemplate.mail })
                this.updateMail(memberTemplate.mail)
            }
        }
        this.setState({
            render: true
        })
    }

    private async updatePostalCode(addressPostalCode: string) {
        const check: boolean | Message = await utilsPatternPostalCode(addressPostalCode)
        if (!isMessage(check)) {
            this.setState({ addressPostalCodePatternValid: check })
        }
        this.lookupAddress()
    }

    private async lookupAddress() {
        // Lookup address
        const postalCode = this.state.addressPostalCode
        const postalCodePatternValid = this.state.addressPostalCodePatternValid
        const houseNumber = this.state.addressHouseNumber

        if (postalCodePatternValid && houseNumber !== undefined) {
            const response = await utilsAddressLookup(postalCode, houseNumber)
            if (!isMessage(response)) {
                const list = response.item
                if (list !== null && list.length > 0) {
                    const streetName = list[0].streetName
                    const city = list[0].city

                    this.setState({
                        addressStreetName: streetName,
                        addressCity: city
                    })
                }
            }
        }
    }

    private async updateStudentNumber(studentNumber: string | undefined) {
        if (studentNumber !== undefined) {
            const check = await utilsPatternStudentNumber(studentNumber)
            if (!isMessage(check)) {
                this.setState({ studentNumberPatternValid: check })
            }
        }
    }

    private async updateMail(mail: string) {
        const check = await utilsPatternMail(mail)
        if (!isMessage(check)) {
            this.setState({ mailPatternValid: check })
        }
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        if (!this.state.registering) {
            this.setState({
                registering: true,
                errorMessage: null
            })

            const useDateOfBirth = (
                this.state.dateOfBirthDay !== undefined &&
                this.state.dateOfBirthMonth !== undefined &&
                this.state.dateOfBirthYear !== undefined
            )

            if (this.state.addressHouseNumber === undefined) {
                this.setState({ registering: false })
                return
            }

            const memberRecaptcha: MemberRecaptchaCreateInput = {
                response: this.state.recaptchaResponse,
                input: {
                    firstName: this.state.firstName,
                    middleName: this.state.middleName,
                    lastName: this.state.lastName,
                    dateOfBirth: useDateOfBirth ? {
                        day: this.state.dateOfBirthDay as number,
                        month: this.state.dateOfBirthMonth as number,
                        year: this.state.dateOfBirthYear as number
                    } : null,
                    address: {
                        city: this.state.addressCity,
                        houseNumber: this.state.addressHouseNumber,
                        houseNumberAddition: this.state.addressHouseNumberAddition !== undefined ? this.state.addressHouseNumberAddition : null,
                        houseNumberLetter: this.state.addressHouseNumberLetter !== undefined ? this.state.addressHouseNumberLetter : null,
                        postalCode: this.state.addressPostalCode,
                        streetName: this.state.addressStreetName
                    },
                    studentNumber: this.state.studentNumber !== undefined ? this.state.studentNumber : null,
                    settings: {
                        showExternal: this.state.showExternal
                    },
                    mail: this.state.mail,
                    password: {
                        password: this.state.password,
                        passwordRepeat: this.state.passwordRepeat
                    }
                }
            }

            const registerResponse = await memberCreateRecaptcha(memberRecaptcha)

            if (registerResponse.successful) {
                if (this.props.memberTemplate !== undefined) {
                    await memberTemplateDelete(
                        this.props.memberTemplate.id,
                        this.props.memberTemplate.memberTemplate.studentNumber
                    )
                }

                this.setState({
                    registering: false,
                    registered: true
                })
            } else {
                this.setState({
                    registering: false,
                    errorMessage: registerResponse.message
                })
                this.resetRecaptcha()
            }
        }
    }

    private resetRecaptcha() {
        if (this.recaptcha !== null) {
            this.recaptcha.reset()
        }
    }

    public render() {
        return this.state.registered
            ? (
                <Redirect to="/login" />
            ) : (this.state.render
                ? (
                    <div className="form-container">
                        <div className="form-box-static">
                            <form
                                className="form"
                                method="post"
                                onSubmit={this.handleSubmit}>

                                <h1>Aanmelden</h1>
                                <h2>Verplichte gegevens</h2>
                                <h3>Naam</h3>
                                <div>
                                    <input
                                        className={this.state.firstName !== ""
                                            ? "form-input text-box-medium valid"
                                            : "form-input text-box-medium"
                                        }
                                        type="text"
                                        placeholder="voornaam"
                                        autoComplete="off"
                                        value={this.state.firstName}
                                        onChange={event => this.setState({ firstName: event.target.value })}
                                        readOnly={this.state.registering}
                                        required />
                                    <input
                                        className={this.state.middleName !== ""
                                            ? "form-input text-box-medium valid"
                                            : "form-input text-box-medium"
                                        }
                                        type="text"
                                        placeholder="tussenvoegsel"
                                        autoComplete="off"
                                        value={this.state.middleName}
                                        onChange={event => this.setState({ middleName: event.target.value })}
                                        readOnly={this.state.registering} />
                                    <input
                                        className={this.state.lastName !== ""
                                            ? "form-input text-box-medium valid"
                                            : "form-input text-box-medium"
                                        }
                                        type="text"
                                        placeholder="achternaam"
                                        autoComplete="off"
                                        value={this.state.lastName}
                                        onChange={event => this.setState({ lastName: event.target.value })}
                                        readOnly={this.state.registering}
                                        required />
                                </div>

                                <h3>Adres</h3>
                                <div>
                                    <input
                                        className={this.state.addressHouseNumber !== undefined
                                            ? (Number.isInteger(this.state.addressHouseNumber)
                                                ? "form-input text-box-small valid"
                                                : "form-input text-box-small not-valid"
                                            ) : "form-input text-box-small"
                                        }
                                        name="middleName"
                                        type="number"
                                        placeholder="huisnummer"
                                        autoComplete="off"
                                        value={this.state.addressHouseNumber}
                                        onChange={async event => {
                                            await this.setState({ addressHouseNumber: toNumberOrUndefined(parseInt(event.target.value)) })
                                            this.lookupAddress()
                                        }}
                                        readOnly={this.state.registering}
                                        required />
                                    <input
                                        className={this.state.addressHouseNumberLetter !== undefined
                                            ? "form-input text-box-extra-small valid"
                                            : "form-input text-box-extra-small"
                                        }
                                        type="text"
                                        placeholder="letter"
                                        autoComplete="off"
                                        value={this.state.addressHouseNumberLetter}
                                        onChange={event => {
                                            let addressHouseNumberLetter: string | undefined = event.target.value
                                            if (addressHouseNumberLetter !== undefined && addressHouseNumberLetter.length === 0) {
                                                addressHouseNumberLetter = undefined
                                            }
                                            this.setState({ addressHouseNumberLetter: addressHouseNumberLetter })
                                        }}
                                        readOnly={this.state.registering} />
                                    <input
                                        className={this.state.addressHouseNumberAddition !== undefined
                                            ? "form-input text-box-extra-small valid"
                                            : "form-input text-box-extra-small"
                                        }
                                        type="text"
                                        placeholder="toevoeging"
                                        autoComplete="off"
                                        value={this.state.addressHouseNumberAddition}
                                        onChange={event => {
                                            let addressHouseNumberAddition: string | undefined = event.target.value
                                            if (addressHouseNumberAddition !== undefined && addressHouseNumberAddition.length === 0) {
                                                addressHouseNumberAddition = undefined
                                            }
                                            this.setState({ addressHouseNumberAddition: addressHouseNumberAddition })
                                        }}
                                        readOnly={this.state.registering} />
                                    <input
                                        className={this.state.addressPostalCode !== ""
                                            ? (this.state.addressPostalCodePatternValid
                                                ? "form-input text-box-small valid"
                                                : "form-input text-box-small not-valid"
                                            ) : "form-input text-box-small"
                                        }
                                        type="text"
                                        placeholder="postcode"
                                        autoComplete="off"
                                        value={this.state.addressPostalCode}
                                        onChange={async event => {
                                            const addressPostalCode = event.target.value
                                                .replace(" ", "")
                                                .toUpperCase()
                                            this.setState({ addressPostalCode: addressPostalCode })
                                            this.updatePostalCode(addressPostalCode)
                                        }}
                                        readOnly={this.state.registering}
                                        required />
                                </div>
                                <div>
                                    <input
                                        className={this.state.addressStreetName !== ""
                                            ? "form-input text-box-large valid"
                                            : "form-input text-box-large"
                                        }
                                        type="text"
                                        placeholder="straatnaam"
                                        autoComplete="off"
                                        value={this.state.addressStreetName}
                                        onChange={event => this.setState({ addressStreetName: event.target.value })}
                                        readOnly={this.state.registering}
                                        required />
                                    <input
                                        className={this.state.addressCity !== ""
                                            ? "form-input text-box-medium valid"
                                            : "form-input text-box-medium"
                                        }
                                        type="text"
                                        placeholder="stad"
                                        autoComplete="off"
                                        value={this.state.addressCity}
                                        onChange={event => this.setState({ addressCity: event.target.value })}
                                        readOnly={this.state.registering}
                                        required />
                                </div>

                                <h3>Studentnummer</h3>
                                <div>
                                    <input
                                        className={this.state.studentNumber !== undefined
                                            ? (this.state.studentNumberPatternValid
                                                ? "form-input text-box-medium valid"
                                                : "form-input text-box-medium not-valid"
                                            ) : "form-input text-box-medium"
                                        }
                                        type="text"
                                        placeholder="studentnummer"
                                        autoComplete="off"
                                        value={this.state.studentNumber}
                                        onChange={async event => {
                                            let studentNumber: string | undefined = event.target.value
                                            if (studentNumber !== undefined && studentNumber.length === 0) {
                                                studentNumber = undefined
                                            }
                                            this.setState({ studentNumber: studentNumber })
                                            this.updateStudentNumber(studentNumber)
                                        }}
                                        readOnly={this.state.registering} />
                                </div>

                                <h2>Optionele gegevens</h2>
                                <h3>Geboortedatum</h3>
                                <div>
                                    <input
                                        className={this.state.dateOfBirthDay !== undefined
                                            ? (Number.isInteger(this.state.dateOfBirthDay)
                                                ? "form-input text-box-small valid"
                                                : "form-input text-box-small not-valid"
                                            ) : "form-input text-box-small"
                                        }
                                        type="number"
                                        placeholder="dag"
                                        autoComplete="off"
                                        value={this.state.dateOfBirthDay}
                                        onChange={event => this.setState({ dateOfBirthDay: toNumberOrUndefined(parseInt(event.target.value)) })}
                                        readOnly={this.state.registering} />
                                    <input
                                        className={this.state.dateOfBirthMonth !== undefined
                                            ? (Number.isInteger(this.state.dateOfBirthMonth)
                                                ? "form-input text-box-small valid"
                                                : "form-input text-box-small not-valid"
                                            ) : "form-input text-box-small"
                                        }
                                        type="number"
                                        placeholder="maand"
                                        autoComplete="off"
                                        value={this.state.dateOfBirthMonth}
                                        onChange={event => this.setState({ dateOfBirthMonth: toNumberOrUndefined(parseInt(event.target.value)) })}
                                        readOnly={this.state.registering} />
                                    <input
                                        className={this.state.dateOfBirthYear !== undefined
                                            ? (Number.isInteger(this.state.dateOfBirthYear)
                                                ? "form-input text-box-small valid"
                                                : "form-input text-box-small not-valid"
                                            ) : "form-input text-box-small"
                                        }
                                        type="number"
                                        placeholder="jaar"
                                        autoComplete="off"
                                        value={this.state.dateOfBirthYear}
                                        onChange={event => this.setState({ dateOfBirthYear: toNumberOrUndefined(parseInt(event.target.value)) })}
                                        readOnly={this.state.registering} />
                                </div>

                                <h2>Inloggegevens</h2>
                                <input
                                    className={this.state.mail !== ""
                                        ? (this.state.mailPatternValid
                                            ? "form-input text-box-large valid"
                                            : "form-input text-box-large not-valid"
                                        ) : "form-input text-box-large"
                                    }
                                    type="text"
                                    placeholder="email"
                                    autoComplete="off"
                                    value={this.state.mail}
                                    onChange={async event => {
                                        const mail = event.target.value
                                        this.setState({ mail: mail })
                                        this.updateMail(mail)
                                    }}
                                    readOnly={this.state.registering}
                                    required />
                                <input
                                    className={this.state.password !== ""
                                        ? "form-input text-box-large valid"
                                        : "form-input text-box-large"
                                    }
                                    type="password"
                                    placeholder="wachtwoord"
                                    autoComplete="off"
                                    value={this.state.password}
                                    onChange={event => this.setState({ password: event.target.value })}
                                    readOnly={this.state.registering}
                                    required />
                                <input
                                    className={this.state.passwordRepeat !== ""
                                        ? (this.state.password === this.state.passwordRepeat
                                            ? "form-input text-box-large valid"
                                            : "form-input text-box-large not-valid"
                                        )
                                        : "form-input text-box-large"
                                    }
                                    type="password"
                                    placeholder="herhaal wachtwoord"
                                    autoComplete="off"
                                    value={this.state.passwordRepeat}
                                    onChange={event => this.setState({ passwordRepeat: event.target.value })}
                                    readOnly={this.state.registering}
                                    required />

                                <h2>Toestemmingen</h2>
                                <div className="form-input-box">
                                    <input
                                        className="form-input form-input-checkbox"
                                        type="checkbox"
                                        checked={this.state.showExternal}
                                        onChange={event => this.setState({ showExternal: event.target.checked })}
                                        readOnly={this.state.registering} />
                                    <b>Extern zichtbaar profiel (optioneel);</b> <i className="text-size-small">hiermee kan je je profiel openlijk zichtbaar stellen voor anderen. Zo kan je je betrokkenheid bij de studievereniging laten zien en bijvoorbeeld toevoegen aan je CV.</i>
                                </div>
                                <div className="form-input-box">
                                    <input
                                        className="form-input form-input-checkbox"
                                        type="checkbox"
                                        readOnly={this.state.registering}
                                        required />
                                    <b>Privacyverklaring;</b> <i className="text-size-small">om lid te worden van de studievereniging moet je akkoord gaan met de privacyverklaring van Computer Science Association Rotterdam. <a target="_blank" rel="noopener noreferrer" href="https://documenten.csarotterdam.nl/Privacyverklaring.pdf">(Privacyverklaring)</a></i>
                                </div>

                                <br />
                                <div className="g-recaptcha">
                                    <ReCAPTCHA
                                        ref={(r) => this.recaptcha = r}
                                        sitekey={RECAPTCHA_SITEKEY}
                                        onChange={(e: any) => this.setState({ recaptchaResponse: e })}
                                    />
                                </div>
                                <br />

                                <div className="form-submit-error-text">
                                    {this.state.errorMessage !== null ? this.state.errorMessage : ""}
                                </div>

                                <input
                                    className="form-input form-submit-button"
                                    type="submit"
                                    value={!this.state.registering ? "Aanmelden" : "Aanmelden..."}
                                    readOnly={this.state.registering} />
                            </form>
                        </div>
                    </div>
                )
                : (
                    <div className="form-container">
                        <div className="form-box-static">
                            <h1>Aanmelden</h1>
                        </div>
                    </div>
                )
            )
    }
}