import React from 'react'
import './Home.css'
import '../Form.css'

import { ASSETS_URL } from '../Global'

export default class Home extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="form-container">
                <div className="form-box-static">
                    <h1>Homepagina</h1>
                    <p>Inmiddels staat de eerste versie van de website online, deze versie bevat voor nu alleen nog de functionele aspecten: aanmelden, inloggen, inzien van jouw profiel en het updaten ervan.</p>
                    <br />
                    <p>Andere onderdelen zoals nieuws & evenementen zullen later op deze plek in te zien zijn.</p>

                    <br />
                    <br />
                    <div className="home-center">
                        <img
                            className="home-coming-soon-image"
                            src={ASSETS_URL + "/logo/black_coming_soon.png"}
                            alt="CSAR Logo" />
                    </div>
                </div>
            </div>
        )
    }
}