import React from 'react'
import { UserLoggedIn, AddressCreateInput, AddressUpdateInput, ContactUpdateInput } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { addressCreate, addressRead, addressUpdate, contactCreate, contactRead, contactUpdate, contactDelete } from '../../Requests';
import { Create, RequestMethod, Read, Update, Delete } from '../../Global';
import { toNumber } from '../../Helper';

interface AddressProps {
    user: UserLoggedIn
}

interface AddressState {
}

export default class Address extends React.Component<AddressProps, AddressState> {
    constructor(props: AddressProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Create */}
            <RequestModule<AddressCreateInput>
                user={this.props.user}
                title="Create Address"
                path="/address/create"
                requestMethod={RequestMethod.POST}
                state={{
                    streetName: "",
                    houseNumber: 0,
                    houseNumberLetter: null,
                    houseNumberAddition: null,
                    postalCode: "",
                    city: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.ADDRESS]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "streetName",
                        type: "text",
                        getter: (thiz: RequestModule<AddressCreateInput>) => thiz.state.streetName,
                        setter: (v: any, thiz: RequestModule<AddressCreateInput>) => thiz.setState({ streetName: v })
                    },
                    {
                        key: "houseNumber",
                        type: "number",
                        getter: (thiz: RequestModule<AddressCreateInput>) => thiz.state.houseNumber,
                        setter: (v: any, thiz: RequestModule<AddressCreateInput>) => thiz.setState({ houseNumber: toNumber(parseInt(v)) })
                    },
                    {
                        key: "houseNumberLetter",
                        type: "text",
                        getter: (thiz: RequestModule<AddressCreateInput>) => thiz.state.houseNumberLetter,
                        setter: (v: string, thiz: RequestModule<AddressCreateInput>) => thiz.setState({ houseNumberLetter: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "houseNumberAddition",
                        type: "text",
                        getter: (thiz: RequestModule<AddressCreateInput>) => thiz.state.houseNumberAddition,
                        setter: (v: string, thiz: RequestModule<AddressCreateInput>) => thiz.setState({ houseNumberAddition: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "postalCode",
                        type: "text",
                        getter: (thiz: RequestModule<AddressCreateInput>) => thiz.state.postalCode,
                        setter: (v: string, thiz: RequestModule<AddressCreateInput>) => thiz.setState({ postalCode: v })
                    },
                    {
                        key: "city",
                        type: "text",
                        getter: (thiz: RequestModule<AddressCreateInput>) => thiz.state.city,
                        setter: (v: string, thiz: RequestModule<AddressCreateInput>) => thiz.setState({ city: v })
                    }
                ]}
                submit={(state) => addressCreate(state)}
            />

            {/* Read */}
            <RequestModule<Input>
                user={this.props.user}
                title="Read Address"
                path="/address/read"
                requestMethod={RequestMethod.POST}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[Read.ADDRESS]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => addressRead(state.id)}
            />

            {/* Update */}
            <RequestModule<AddressUpdateInput>
                user={this.props.user}
                title="Update Address"
                path="/address/update"
                requestMethod={RequestMethod.PUT}
                state={{
                    id: "",
                    streetName: "",
                    houseNumber: 0,
                    houseNumberLetter: null,
                    houseNumberAddition: null,
                    postalCode: "",
                    city: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.ADDRESS]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<AddressUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<AddressUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "streetName",
                        type: "text",
                        getter: (thiz: RequestModule<AddressUpdateInput>) => thiz.state.streetName,
                        setter: (v: any, thiz: RequestModule<AddressUpdateInput>) => thiz.setState({ streetName: v })
                    },
                    {
                        key: "houseNumber",
                        type: "number",
                        getter: (thiz: RequestModule<AddressUpdateInput>) => thiz.state.houseNumber,
                        setter: (v: any, thiz: RequestModule<AddressUpdateInput>) => thiz.setState({ houseNumber: toNumber(parseInt(v)) })
                    },
                    {
                        key: "houseNumberLetter",
                        type: "text",
                        getter: (thiz: RequestModule<AddressUpdateInput>) => thiz.state.houseNumberLetter,
                        setter: (v: string, thiz: RequestModule<AddressUpdateInput>) => thiz.setState({ houseNumberLetter: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "houseNumberAddition",
                        type: "text",
                        getter: (thiz: RequestModule<AddressUpdateInput>) => thiz.state.houseNumberAddition,
                        setter: (v: string, thiz: RequestModule<AddressUpdateInput>) => thiz.setState({ houseNumberAddition: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "postalCode",
                        type: "text",
                        getter: (thiz: RequestModule<AddressUpdateInput>) => thiz.state.postalCode,
                        setter: (v: string, thiz: RequestModule<AddressUpdateInput>) => thiz.setState({ postalCode: v })
                    },
                    {
                        key: "city",
                        type: "text",
                        getter: (thiz: RequestModule<AddressUpdateInput>) => thiz.state.city,
                        setter: (v: string, thiz: RequestModule<AddressUpdateInput>) => thiz.setState({ city: v })
                    }
                ]}
                submit={(state) => addressUpdate(state)}
            />

            {/* Create */}
            <RequestModule<ContactCreateInput>
                user={this.props.user}
                title="Create Contact"
                path="/contact/create"
                requestMethod={RequestMethod.POST}
                state={{
                    name: "",
                    streetName: "",
                    houseNumber: 0,
                    houseNumberLetter: null,
                    houseNumberAddition: null,
                    postalCode: "",
                    city: "",
                    mail: "",
                    telephoneNumber: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.CONTACT]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "name",
                        type: "text",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.name,
                        setter: (v: any, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ name: v })
                    },
                    {
                        key: "streetName",
                        type: "text",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.streetName,
                        setter: (v: any, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ streetName: v })
                    },
                    {
                        key: "houseNumber",
                        type: "number",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.houseNumber,
                        setter: (v: any, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ houseNumber: toNumber(parseInt(v)) })
                    },
                    {
                        key: "houseNumberLetter",
                        type: "text",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.houseNumberLetter,
                        setter: (v: string, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ houseNumberLetter: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "houseNumberAddition",
                        type: "text",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.houseNumberAddition,
                        setter: (v: string, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ houseNumberAddition: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "postalCode",
                        type: "text",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.postalCode,
                        setter: (v: string, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ postalCode: v })
                    },
                    {
                        key: "city",
                        type: "text",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.city,
                        setter: (v: string, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ city: v })
                    },
                    {
                        key: "mail",
                        type: "text",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.mail,
                        setter: (v: any, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ mail: v })
                    },
                    {
                        key: "telephoneNumber",
                        type: "text",
                        getter: (thiz: RequestModule<ContactCreateInput>) => thiz.state.telephoneNumber,
                        setter: (v: any, thiz: RequestModule<ContactCreateInput>) => thiz.setState({ telephoneNumber: v })
                    }
                ]}
                submit={(state) => contactCreate({
                    name: state.name,
                    address: {
                        streetName: state.streetName,
                        houseNumber: state.houseNumber,
                        houseNumberLetter: state.houseNumberLetter,
                        houseNumberAddition: state.houseNumberAddition,
                        postalCode: state.postalCode,
                        city: state.city
                    },
                    mail: state.mail,
                    telephoneNumber: state.telephoneNumber
                })}
            />

            {/* Read */}
            <RequestModule<{}>
                user={this.props.user}
                title="Read all Contact information"
                path="/contact/read"
                requestMethod={RequestMethod.GET}
                state={{}}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => contactRead()}
            />

            {/* Update */}
            <RequestModule<ContactUpdateInput>
                user={this.props.user}
                title="Update Contact"
                path="/contact/update"
                requestMethod={RequestMethod.PUT}
                state={{
                    id: "",
                    name: "",
                    mail: "",
                    telephoneNumber: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.CONTACT]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<ContactUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<ContactUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "name",
                        type: "text",
                        getter: (thiz: RequestModule<ContactUpdateInput>) => thiz.state.name,
                        setter: (v: any, thiz: RequestModule<ContactUpdateInput>) => thiz.setState({ name: v })
                    },
                    {
                        key: "mail",
                        type: "text",
                        getter: (thiz: RequestModule<ContactUpdateInput>) => thiz.state.mail,
                        setter: (v: any, thiz: RequestModule<ContactUpdateInput>) => thiz.setState({ mail: v })
                    },
                    {
                        key: "telephoneNumber",
                        type: "text",
                        getter: (thiz: RequestModule<ContactUpdateInput>) => thiz.state.telephoneNumber,
                        setter: (v: any, thiz: RequestModule<ContactUpdateInput>) => thiz.setState({ telephoneNumber: v })
                    }
                ]}
                submit={(state) => contactUpdate(state)}
            />

            {/* Delete */}
            <RequestModule<Input>
                user={this.props.user}
                title="Delete Contact"
                path="/contact/delete"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.CONTACT]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => contactDelete(state.id)}
            />
        </div>
    }
}

interface Input {
    id: string
}

interface ContactCreateInput {
    name: string
    streetName: string
    houseNumber: number
    houseNumberLetter: string | null
    houseNumberAddition: string | null
    postalCode: string
    city: string
    mail: string
    telephoneNumber: string
}