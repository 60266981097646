import React from 'react'
import { UserLoggedIn } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { memberTemplateCreate, memberTemplateCreateBulk, memberTemplateRead, memberTemplateDelete } from '../../Requests';
import { Create, RequestMethod } from '../../Global';

import { MemberTemplateContent } from '../../ApiModels'

interface MemberTemplateProps {
    user: UserLoggedIn
}

interface MemberTemplateState {
    memberTemplate: MemberTemplateContent
}

export default class MemberTemplate extends React.Component<MemberTemplateProps, MemberTemplateState> {
    constructor(props: MemberTemplateProps) {
        super(props)
        this.state = {
            memberTemplate: {
                firstName: "string",
                middleName: "string | null",
                lastName: "string",
                dateOfBirth: {
                    year: 2001,
                    month: 2,
                    day: 3
                },
                postalCode: "string | null",
                houseNumber: 0,
                houseNumberLetter: "string | null",
                houseNumberAddition: "string | null",
                studentNumber: "string",
                mail: "string | null",
            }
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            <div className="module-header-text">
                <h3>Note</h3>
                <p>You can use the helper functions defined in the <b>csar_tools</b> to easily convert from CSV to JSON format.</p>

                <h3>Model</h3>
                <p>Note: `date of birth` and `houseNumber` can also be `null`, also `studentNumber` must be in the appropriate format.</p>
                <pre>{JSON.stringify(this.state.memberTemplate, null, 2)}</pre>
            </div>
            <br />

            {/* Create */}
            <RequestModule<MemberTemplateCreateInputState>
                user={this.props.user}
                title="Create Member Template (automatically sends an email to <studentNumber>@hr.nl to finish sign up)"
                path="/memberTemplate/create"
                requestMethod={RequestMethod.POST}
                state={{
                    input: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.MEMBER_TEMPLATE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberTemplate",
                        type: "text",
                        getter: (thiz: RequestModule<MemberTemplateCreateInputState>) => thiz.state.input,
                        setter: (v: any, thiz: RequestModule<MemberTemplateCreateInputState>) => thiz.setState({ input: v })
                    }
                ]}
                submit={(state) => memberTemplateCreate(state.input)}
            />

            <RequestModule<MemberTemplateCreateInputState>
                user={this.props.user}
                title="Create Member Templates (automatically sends emails to <studentNumber>@hr.nl to finish sign up)"
                path="/memberTemplate/create/bulk"
                requestMethod={RequestMethod.POST}
                state={{
                    input: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.MEMBER_TEMPLATE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberTemplates",
                        type: "text",
                        getter: (thiz: RequestModule<MemberTemplateCreateInputState>) => thiz.state.input,
                        setter: (v: any, thiz: RequestModule<MemberTemplateCreateInputState>) => thiz.setState({ input: v })
                    }
                ]}
                submit={(state) => memberTemplateCreateBulk(state.input)}
            />

            <RequestModule<MemberTemplateInputState>
                user={this.props.user}
                title="Read Member Template (only returns on correct <id> - <studentNumber> combination)"
                path="/memberTemplate/read"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    studentNumber: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberTemplateInputState>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberTemplateInputState>) => thiz.setState({ id: v })
                    },
                    {
                        key: "studentNumber",
                        type: "text",
                        getter: (thiz: RequestModule<MemberTemplateInputState>) => thiz.state.studentNumber,
                        setter: (v: any, thiz: RequestModule<MemberTemplateInputState>) => thiz.setState({ studentNumber: v })
                    }
                ]}
                submit={(state) => memberTemplateRead(state.id, state.studentNumber)}
            />

            <RequestModule<MemberTemplateInputState>
                user={this.props.user}
                title="Delete Member Template (only deletes on correct <id> - <studentNumber> combination"
                path="/memberTemplate/delete"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    studentNumber: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberTemplateInputState>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberTemplateInputState>) => thiz.setState({ id: v })
                    },
                    {
                        key: "studentNumber",
                        type: "text",
                        getter: (thiz: RequestModule<MemberTemplateInputState>) => thiz.state.studentNumber,
                        setter: (v: any, thiz: RequestModule<MemberTemplateInputState>) => thiz.setState({ studentNumber: v })
                    }
                ]}
                submit={(state) => memberTemplateDelete(state.id, state.studentNumber)}
            />
        </div>
    }
}

interface MemberTemplateCreateInputState {
    input: string
}

interface MemberTemplateInputState {
    id: string
    studentNumber: string
}