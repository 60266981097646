import React from 'react'
import { UserLoggedIn, NewsCreateInput, NewsCollaboratorCreateDeleteInput, NewsCommitteeCreateDeleteInput, NewsTagCreateDeleteInput, NewsUpdateInput } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { newsCreate, newsCreateCollaborator, newsCreateCommittee, newsCreateTag, newsReadById, newsRead, newsDelete, newsDeleteCollaborator, newsDeleteCommittee, newsDeleteTag } from '../../Requests';
import { Create, RequestMethod, Update, Delete } from '../../Global';
import { toNumber } from '../../Helper';

interface NewsProps {
    user: UserLoggedIn
}

interface NewsState {
}

export default class News extends React.Component<NewsProps, NewsState> {
    constructor(props: NewsProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Create */}
            <RequestModule<NewsCreateInput>
                user={this.props.user}
                title="Create News"
                path="/news/create"
                requestMethod={RequestMethod.POST}
                state={{
                    title: "",
                    subTitle: "",
                    shortDescription: "",
                    longDescription: "",
                    imgUrl: "",
                    content: "",
                    schoolYear: 0
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.NEWS]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "title",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCreateInput>) => thiz.state.title,
                        setter: (v: any, thiz: RequestModule<NewsCreateInput>) => thiz.setState({ title: v })
                    },
                    {
                        key: "subTitle",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCreateInput>) => thiz.state.subTitle,
                        setter: (v: any, thiz: RequestModule<NewsCreateInput>) => thiz.setState({ subTitle: v })
                    },
                    {
                        key: "shortDescription",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCreateInput>) => thiz.state.shortDescription,
                        setter: (v: any, thiz: RequestModule<NewsCreateInput>) => thiz.setState({ shortDescription: v })
                    },
                    {
                        key: "longDescription",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCreateInput>) => thiz.state.longDescription,
                        setter: (v: any, thiz: RequestModule<NewsCreateInput>) => thiz.setState({ longDescription: v })
                    },
                    {
                        key: "imgUrl",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCreateInput>) => thiz.state.imgUrl,
                        setter: (v: any, thiz: RequestModule<NewsCreateInput>) => thiz.setState({ imgUrl: v })
                    },
                    {
                        key: "content",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCreateInput>) => thiz.state.content,
                        setter: (v: any, thiz: RequestModule<NewsCreateInput>) => thiz.setState({ content: v })
                    },
                    {
                        key: "schoolYear",
                        type: "number",
                        getter: (thiz: RequestModule<NewsCreateInput>) => thiz.state.schoolYear,
                        setter: (v: any, thiz: RequestModule<NewsCreateInput>) => thiz.setState({ schoolYear: toNumber(parseInt(v)) })
                    }
                ]}
                submit={(state) => newsCreate(state)}
            />

            <RequestModule<NewsCollaboratorCreateDeleteInput>
                user={this.props.user}
                title="Associate Collaborator with News"
                path="/news/create/collaborator"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    collaboratorId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.NEWS_COLLABORATOR]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCollaboratorCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<NewsCollaboratorCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "collaboratorId",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCollaboratorCreateDeleteInput>) => thiz.state.collaboratorId,
                        setter: (v: any, thiz: RequestModule<NewsCollaboratorCreateDeleteInput>) => thiz.setState({ collaboratorId: v })
                    }
                ]}
                submit={(state) => newsCreateCollaborator(state)}
            />

            <RequestModule<NewsCommitteeCreateDeleteInput>
                user={this.props.user}
                title="Associate Committee with News"
                path="/news/create/committee"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.NEWS_COMMITTEE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCommitteeCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<NewsCommitteeCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCommitteeCreateDeleteInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<NewsCommitteeCreateDeleteInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => newsCreateCommittee(state)}
            />

            <RequestModule<NewsTagCreateDeleteInput>
                user={this.props.user}
                title="Create News Tag"
                path="/news/create/tag"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    tag: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.NEWS_TAG]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<NewsTagCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<NewsTagCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "tag",
                        type: "text",
                        getter: (thiz: RequestModule<NewsTagCreateDeleteInput>) => thiz.state.tag,
                        setter: (v: any, thiz: RequestModule<NewsTagCreateDeleteInput>) => thiz.setState({ tag: v })
                    }
                ]}
                submit={(state) => newsCreateTag(state)}
            />

            {/* Read */}
            <RequestModule<{}>
                user={this.props.user}
                title="Read News"
                path="/news/read"
                requestMethod={RequestMethod.GET}
                state={{}}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => newsRead()}
            />

            <RequestModule<Input>
                user={this.props.user}
                title="Read News by id"
                path="/news/read/{id}"
                requestMethod={RequestMethod.GET}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => newsReadById(state.id)}
            />

            {/* Update */}
            <RequestModule<NewsUpdateInput>
                user={this.props.user}
                title="Update News"
                path="/news/update"
                requestMethod={RequestMethod.PUT}
                state={{
                    id: "",
                    title: "",
                    subTitle: "",
                    shortDescription: "",
                    longDescription: "",
                    imgUrl: "",
                    content: "",
                    schoolYear: 0
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.NEWS]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<NewsUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<NewsUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "title",
                        type: "text",
                        getter: (thiz: RequestModule<NewsUpdateInput>) => thiz.state.title,
                        setter: (v: any, thiz: RequestModule<NewsUpdateInput>) => thiz.setState({ title: v })
                    },
                    {
                        key: "subTitle",
                        type: "text",
                        getter: (thiz: RequestModule<NewsUpdateInput>) => thiz.state.subTitle,
                        setter: (v: any, thiz: RequestModule<NewsUpdateInput>) => thiz.setState({ subTitle: v })
                    },
                    {
                        key: "shortDescription",
                        type: "text",
                        getter: (thiz: RequestModule<NewsUpdateInput>) => thiz.state.shortDescription,
                        setter: (v: any, thiz: RequestModule<NewsUpdateInput>) => thiz.setState({ shortDescription: v })
                    },
                    {
                        key: "longDescription",
                        type: "text",
                        getter: (thiz: RequestModule<NewsUpdateInput>) => thiz.state.longDescription,
                        setter: (v: any, thiz: RequestModule<NewsUpdateInput>) => thiz.setState({ longDescription: v })
                    },
                    {
                        key: "imgUrl",
                        type: "text",
                        getter: (thiz: RequestModule<NewsUpdateInput>) => thiz.state.imgUrl,
                        setter: (v: any, thiz: RequestModule<NewsUpdateInput>) => thiz.setState({ imgUrl: v })
                    },
                    {
                        key: "content",
                        type: "text",
                        getter: (thiz: RequestModule<NewsUpdateInput>) => thiz.state.content,
                        setter: (v: any, thiz: RequestModule<NewsUpdateInput>) => thiz.setState({ content: v })
                    },
                    {
                        key: "schoolYear",
                        type: "number",
                        getter: (thiz: RequestModule<NewsUpdateInput>) => thiz.state.schoolYear,
                        setter: (v: any, thiz: RequestModule<NewsUpdateInput>) => thiz.setState({ schoolYear: toNumber(parseInt(v)) })
                    }
                ]}
                submit={(state) => newsCreate(state)}
            />

            {/* Delete */}
            <RequestModule<Input>
                user={this.props.user}
                title="Delete News"
                path="/news/delete"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.NEWS]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => newsDelete(state.id)}
            />

            <RequestModule<NewsCollaboratorCreateDeleteInput>
                user={this.props.user}
                title="Delete Collaborator association with News"
                path="/news/delete/collaborator"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    collaboratorId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.NEWS_COLLABORATOR]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCollaboratorCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<NewsCollaboratorCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "collaboratorId",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCollaboratorCreateDeleteInput>) => thiz.state.collaboratorId,
                        setter: (v: any, thiz: RequestModule<NewsCollaboratorCreateDeleteInput>) => thiz.setState({ collaboratorId: v })
                    }
                ]}
                submit={(state) => newsDeleteCollaborator(state)}
            />

            <RequestModule<NewsCommitteeCreateDeleteInput>
                user={this.props.user}
                title="Delete Committee association with News"
                path="/news/delete/committee"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.NEWS_COMMITTEE]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCommitteeCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<NewsCommitteeCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<NewsCommitteeCreateDeleteInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<NewsCommitteeCreateDeleteInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => newsDeleteCommittee(state)}
            />

            <RequestModule<NewsTagCreateDeleteInput>
                user={this.props.user}
                title="Delete News Tag"
                path="/news/delete/tag"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    tag: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.NEWS_TAG]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<NewsTagCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<NewsTagCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "tag",
                        type: "text",
                        getter: (thiz: RequestModule<NewsTagCreateDeleteInput>) => thiz.state.tag,
                        setter: (v: any, thiz: RequestModule<NewsTagCreateDeleteInput>) => thiz.setState({ tag: v })
                    }
                ]}
                submit={(state) => newsDeleteTag(state)}
            />
        </div>
    }
}

interface Input {
    id: string
}