import React from 'react'
import { UserLoggedIn } from '../../ApiModels'

import '../Admin.css'
import Address from './Address';
import Collaborator from './Collaborator';
import Committee from './Committee';
import Event from './Event';
import File from './File';
import News from './News';

interface OtherProps {
    user: UserLoggedIn
}

interface OtherState {
    menu: "address" | "collaborator" | "committee" | "event" | "file" | "news"
}

export default class Other extends React.Component<OtherProps, OtherState> {
    constructor(props: OtherProps) {
        super(props)
        this.state = {
            menu: "address"
        }

        this.content = this.content.bind(this)
    }

    async componentDidMount() {

    }

    content() {
        switch (this.state.menu) {
            case "address":
                return <Address user={this.props.user} />
            case "collaborator":
                return <Collaborator user={this.props.user} />
            case "committee":
                return <Committee user={this.props.user} />
            case "event":
                return <Event user={this.props.user} />
            case "file":
                return <File user={this.props.user} />
            case "news":
                return <News user={this.props.user} />

            default:
                return "Page not found..."
        }
    }

    public render() {
        return <div>
            <div className="menu">
                <div className={this.state.menu === "address"
                    ? "menu-block menu-block-selected"
                    : "menu-block"
                } onClick={() => this.setState({ menu: "address" })}>
                    <p className="menu-block-content">Address</p>
                </div>
                <div className={this.state.menu === "collaborator"
                    ? "menu-block menu-block-selected"
                    : "menu-block"
                } onClick={() => this.setState({ menu: "collaborator" })}>
                    <p className="menu-block-content">Collaborator</p>
                </div>
                <div className={this.state.menu === "committee"
                    ? "menu-block menu-block-selected"
                    : "menu-block"
                } onClick={() => this.setState({ menu: "committee" })}>
                    <p className="menu-block-content">Comittee</p>
                </div>
                <div className={this.state.menu === "event"
                    ? "menu-block menu-block-selected"
                    : "menu-block"
                } onClick={() => this.setState({ menu: "event" })}>
                    <p className="menu-block-content">Event</p>
                </div>
                <div className={this.state.menu === "file"
                    ? "menu-block menu-block-selected"
                    : "menu-block"
                } onClick={() => this.setState({ menu: "file" })}>
                    <p className="menu-block-content">File</p>
                </div>
                <div className={this.state.menu === "news"
                    ? "menu-block menu-block-selected"
                    : "menu-block"
                } onClick={() => this.setState({ menu: "news" })}>
                    <p className="menu-block-content">News</p>
                </div>
            </div>

            {/* Content */}
            <br />
            {this.content()}
        </div>
    }
}