import React from 'react'
import './Footer.css'
import { NavLink } from 'react-router-dom';

export default class Footer extends React.Component {

    render() {
        return (
            <div>
                <div className="footer-container">
                    <div className="footer-left-container">
                        <div className="footer-linkbox">
                            {/*TODO: Add links to pages when pages are available*/}
                            <NavLink to="/">
                                <p className="footer-text-short link">Home</p>
                            </NavLink>
                            <NavLink to="/">
                                <p className="footer-text link">Home</p>
                            </NavLink>
                            <p className="footer-text-short"> - </p>
                            <p className="footer-text"> - </p>
                            <NavLink to="/contact">
                                <p className="footer-text-short link">Contact</p>
                            </NavLink>
                            <NavLink to="/contact">
                                <p className="footer-text link">Contact</p>
                            </NavLink>
                        </div>
                        <p className="footer-text-short">&copy; 2019 CSAR</p>
                        <p className="footer-text">&copy; 2019 Computer Science Association Rotterdam. Alle rechten
                                voorbehouden</p>
                    </div>
                    <div className="footer-right-container">
                        <p className="footer-text-right-short">KvK 74532871</p>
                        <p className="footer-text-right">KvK 74532871</p>
                        <br/>
                        <p className="footer-text-right-short">Wijnhaven 107</p>
                        <p className="footer-text-right">Wijnhaven 107 3011WN Rotterdam</p>
                    </div>
                </div>
            </div>
        )
    }
}