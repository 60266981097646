export const API_URL = "https://api.csarotterdam.nl"
export const ASSETS_URL = "https://assets.csarotterdam.nl"

export const RECAPTCHA_SITEKEY = "6LdoH5YUAAAAALy6VgbtINf9QgVggDjLzGewbHMb"

export enum RequestMethod {
    GET = "get",
    POST = "post",
    PUT = "put",
    PATCH = "patch",
    DELETE = "delete",
}

export enum Info {
    AUTH = "AUTH",

    LOG = "LOG",

    STATISTICS = "STATISTICS",

    MEMBER_MAIL = "MEMBER_MAIL",
    MEMBER_CUSTO_MAIL = "MEMBER_CUSTOM_MAIL",
    MAIL_LIST_MAIL = "MAIL_LIST_MAIL",
    CUSTOM_MAIL_LIST_MAIL = "CUSTOM_MAIL_LIST_MAIL",

    MEMBER_TEMPLATE = "MEMBER_TEMPLATE",
}

export enum Create {
    AUTH = "AUTH",

    ADDRESS = "ADDRESS",
    CONTACT = "CONTACT",

    MEMBER_TEMPLATE = "MEMBER_TEMPLATE",
    MEMBER = "MEMBER",
    MEMBER_COMMITTEE = "MEMBER_COMMITTEE",

    COMMITTEE = "COMMITTEE",

    COLLABORATOR = "COLLABORATOR",
    COLLABORATOR_COMMITTEE = "COLLABORATOR_COMMITTEE",

    NEWS = "NEWS",
    NEWS_COMMITTEE = "NEWS_COMMITTEE",
    NEWS_COLLABORATOR = "NEWS_COLLABORATOR",
    NEWS_TAG = "NEWS_TAG",

    EVENT = "EVENT",
    EVENT_TYPE = "EVENT_TYPE",
    EVENT_POSTER = "EVENT_POSTER",
    EVENT_TAG = "EVENT_TAG",
    EVENT_JOINED_MEMBER = "EVENT_JOINED_MEMBER",
    EVENT_COMMITTEE = "EVENT_COMMITTEE",
    EVENT_COLLABORATOR = "EVENT_COLLABORATOR",
    EVENT_ORGANIZER = "EVENT_ORGANIZER",

    FILE = "FILE",
    FILE_TYPE = "FILE_TYPE",
    FILE_COMMITTEE = "FILE_COMMITTEE",
    FILE_NEWS = "FILE_NEWS",
    FILE_EVENT = "FILE_EVENT",
    FILE_TAG = "FILE_TAG",
}

export enum Read {
    AUTH = "AUTH",

    ADDRESS = "ADDRESS",

    MEMBER = "MEMBER",
    MEMBER_ADDITIONAL_INFO = "MEMBER_ADDITIONAL_INFO",
    MEMBER_SETTINGS = "MEMBER_SETTINGS",
    MEMBER_IS_ACTIVE = "MEMBER_IS_ACTIVE",
    MEMBER_IS_ACTIVE_LIST = "MEMBER_IS_ACTIVE_LIST",
    MEMBER_EVENT_JOINED = "MEMBER_EVENT_JOINED",
    MEMBER_LOG_MEMBER_ACTED_BY = "MEMBER_LOG_MEMBER_ACTED_BY",
    MEMBER_LOG_MEMBER_ACTED_ON = "MEMBER_LOG_MEMBER_ACTED_ON",

    MEMBER_STUDENT_NUMBER = "MEMBER_STUDENT_NUMBER",
    MEMBER_MAIL = "MEMBER_MAIL",

    EVENT_JOINED_MEMBERS = "EVENT_JOINED_MEMBERS",
}

export enum Update {
    AUTH = "AUTH",

    ADDRESS = "ADDRESS",
    CONTACT = "CONTACT",

    MEMBER_FIRST_MIDDLE_AND_LAST_NAME = "MEMBER_FIRST_MIDDLE_AND_LAST_NAME",
    MEMBER_DATE_OF_BIRTH = "MEMBER_DATE_OF_BIRTH",
    MEMBER_ADDRESS = "MEMBER_ADDRESS",
    MEMBER_STUDENT_NUMBER = "MEMBER_STUDENT_NUMBER",
    MEMBER_MAIL = "MEMBER_MAIL",
    MEMBER_PASSWORD = "MEMBER_PASSWORD",
    MEMBER_ACTIVE = "MEMBER_ACTIVE",
    MEMBER_SETTINGS = "MEMBER_SETTINGS",

    MEMBER_BOARD = "MEMBER_BOARD",

    MEMBER_COMMITTEE_IMAGE_URL = "MEMBER_COMMITTEE_IMAGE_URL",
    MEMBER_COMMITTEE_SHORT_DESCRIPTION = "MEMBER_COMMITTEE_SHORT_DESCRIPTION",
    MEMBER_COMMITTEE_LONG_DESCRIPTION = "MEMBER_COMMITTEE_LONG_DESCRIPTION",

    MEMBER_COMMITTEE_COMMITTEE_TAG = "MEMBER_COMMITTEE_COMMITTEE_TAG",
    MEMBER_COMMITTEE_COMMITTEE_CHAIRMAN_TAG = "MEMBER_COMMITTEE_COMMITTEE_CHAIRMAN_TAG",

    COMMITTEE = "COMMITTEE",

    COLLABORATOR = "COLLABORATOR",

    NEWS = "NEWS",

    EVENT = "EVENT",
    EVENT_TYPE = "EVENT_TYPE",
    EVENT_POSTER = "EVENT_POSTER",

    FILE = "FILE",
    FILE_TYPE = "FILE_TYPE",
}

export enum Delete {
    AUTH = "AUTH",

    CONTACT = "CONTACT",

    LOG = "LOG",

    MEMBER_COMMITTEE = "MEMBER_COMMITTEE",
    MEMBER = "MEMBER",

    COLLABORATOR = "COLLABORATOR",
    COLLABORATOR_COMMITTEE = "COLLABORATOR_COMMITTEE",

    NEWS = "NEWS",
    NEWS_COMMITTEE = "NEWS_COMMITTEE",
    NEWS_COLLABORATOR = "NEWS_COLLABORATOR",
    NEWS_TAG = "NEWS_TAG",

    EVENT = "EVENT",
    EVENT_TYPE = "EVENT_TYPE",
    EVENT_POSTER = "EVENT_POSTER",
    EVENT_TAG = "EVENT_TAG",
    EVENT_JOINED_MEMBER = "EVENT_JOINED_MEMBER",
    EVENT_COMMITTEE = "EVENT_COMMITTEE",
    EVENT_COLLABORATOR = "EVENT_COLLABORATOR",
    EVENT_ORGANIZER = "EVENT_ORGANIZER",

    FILE = "FILE",
    FILE_TYPE = "FILE_TYPE",
    FILE_COMMITTEE = "FILE_COMMITTEE",
    FILE_NEWS = "FILE_NEWS",
    FILE_EVENT = "FILE_EVENT",
    FILE_TAG = "FILE_TAG",
}
