import React from 'react'
import { UserLoggedIn, AuthRoles, Message, ItemWithMessage, AuthRolesComponent } from '../../ApiModels'

import '../Admin.css'
import { RequestMethod, Info, Create, Read, Update, Delete } from '../../Global';
import CustomModule from '../CustomModule';
import { authListRoles, authRoleCreate, authRoleRead, authRoleUpdate, authRoleDelete } from '../../Requests';

import { isMessage } from '../../Helper';
import RequestModule from '../RequestModule';

interface AuthRolesProps {
    user: UserLoggedIn
}

interface AuthRolesState {
}

export default class MemberAuthRoles extends React.Component<AuthRolesProps, AuthRolesState> {
    constructor(props: AuthRolesProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Create */}
            <RequestModule<AuthRoleMemberRoleInput>
                user={this.props.user}
                title="Create Role for Member"
                path="/auth/role/create"
                requestMethod={RequestMethod.POST}
                state={{
                    memberId: "",
                    role: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.AUTH]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<AuthRoleMemberRoleInput>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<AuthRoleMemberRoleInput>) => thiz.setState({ memberId: v })
                    },
                    {
                        key: "role",
                        type: "text",
                        getter: (thiz: RequestModule<AuthRoleMemberRoleInput>) => thiz.state.role,
                        setter: (v: any, thiz: RequestModule<AuthRoleMemberRoleInput>) => thiz.setState({ role: v })
                    }
                ]}
                submit={(state) => authRoleCreate(state.memberId, state.role)}
            />

            {/* Read */}
            <RequestModule<AuthRoleMemberInput>
                user={this.props.user}
                title="Read Role of Member"
                path="/auth/role/read"
                requestMethod={RequestMethod.POST}
                state={{
                    memberId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[Read.AUTH]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<AuthRoleMemberInput>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<AuthRoleMemberInput>) => thiz.setState({ memberId: v })
                    }
                ]}
                submit={(state) => authRoleRead(state.memberId)}
            />

            {/* Update */}
            <RequestModule<AuthRoleMemberRoleInput>
                user={this.props.user}
                title="Update Role for Member"
                path="/auth/role/update"
                requestMethod={RequestMethod.PATCH}
                state={{
                    memberId: "",
                    role: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.AUTH]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<AuthRoleMemberRoleInput>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<AuthRoleMemberRoleInput>) => thiz.setState({ memberId: v })
                    },
                    {
                        key: "role",
                        type: "text",
                        getter: (thiz: RequestModule<AuthRoleMemberRoleInput>) => thiz.state.role,
                        setter: (v: any, thiz: RequestModule<AuthRoleMemberRoleInput>) => thiz.setState({ role: v })
                    }
                ]}
                submit={(state) => authRoleUpdate(state.memberId, state.role)}
            />

            {/* Delete */}
            <RequestModule<AuthRoleMemberInput>
                user={this.props.user}
                title="Delete Role of Member"
                path="/auth/role/read"
                requestMethod={RequestMethod.DELETE}
                state={{
                    memberId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.AUTH]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<AuthRoleMemberInput>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<AuthRoleMemberInput>) => thiz.setState({ memberId: v })
                    }
                ]}
                submit={(state) => authRoleDelete(state.memberId)}
            />

            <CustomModule<ItemWithMessage<AuthRoles> | Message, Array<AuthRolesComponent>>
                id={undefined}
                user={this.props.user}
                title="Member Roles"
                description="View all Auth Roles for Members."
                requestMethod={RequestMethod.POST}
                permissionsInfo={[Info.AUTH]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                call={authListRoles}
                map={(data) => {
                    if (isMessage(data)) {
                        throw new Error(`Error: ${data.message}`)
                    } else if (data.message.successful && data.item !== null) {
                        return data.item.roles
                    } else {
                        return undefined
                    }
                }}
                component={(data) =>
                    <div>
                        <table id="table">
                            <tbody>
                                <tr>
                                    <th>Role</th>
                                    <th>First name</th>
                                    <th>Middle name</th>
                                    <th>Last name</th>
                                    <th>Committee Member?</th>
                                    <th>Member Id</th>
                                </tr>
                            </tbody>
                            {data.map((item) => <tbody key={item.role}>
                                <tr>
                                    <td>{item.role}</td>
                                </tr>
                                {item.members.map((member => <tr key={member.id}>
                                    <td></td>
                                    <td>{member.firstName}</td>
                                    <td>{member.middleName}</td>
                                    <td>{member.lastName}</td>
                                    <td>{member.memberCommittee !== null ? "yes" : ""}</td>
                                    <td><pre>{member.id}</pre></td>
                                </tr>))}
                            </tbody>)}
                        </table>
                    </div>
                }
            />
        </div>
    }
}

interface AuthRoleMemberRoleInput {
    memberId: string
    role: string
}

interface AuthRoleMemberInput {
    memberId: string
}