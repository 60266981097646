import React from 'react'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';
import { UserLoggedIn, MemberJoinLeaveReport, ItemWithMessage, Message, DateTemplate } from '../../ApiModels'

import '../Admin.css'
import '../../Form.css'
import CustomModule from '../CustomModule';
import { RequestMethod, Info } from '../../Global';
import { statisticsTimelineJoinLeave } from '../../Requests';
import { isMessage, toNumberOrUndefined } from '../../Helper';

interface StatisticsProps {
    user: UserLoggedIn
}

interface StatisticsState {
    timelineJoinLeaveId: number,
    timelineJoinLeaveStartDateYear: number | undefined
    timelineJoinLeaveStartDateMonth: number | undefined
    timelineJoinLeaveStartDateDay: number | undefined
    timelineJoinLeaveEndDateYear: number | undefined
    timelineJoinLeaveEndDateMonth: number | undefined
    timelineJoinLeaveEndDateDay: number | undefined
    timelineJoinLeaveStartDateInclusive: DateTemplate | null
    timelineJoinLeaveEndDateInclusive: DateTemplate | null
}

export default class Statistics extends React.Component<StatisticsProps, StatisticsState> {
    constructor(props: StatisticsProps) {
        super(props)
        this.state = {
            timelineJoinLeaveId: Math.random(),
            timelineJoinLeaveStartDateYear: undefined,
            timelineJoinLeaveStartDateMonth: undefined,
            timelineJoinLeaveStartDateDay: undefined,
            timelineJoinLeaveEndDateYear: undefined,
            timelineJoinLeaveEndDateMonth: undefined,
            timelineJoinLeaveEndDateDay: undefined,
            timelineJoinLeaveStartDateInclusive: null,
            timelineJoinLeaveEndDateInclusive: null
        }

        this.handleSubmitTimelineJoinLeave = this.handleSubmitTimelineJoinLeave.bind(this)
    }

    async componentDidMount() {
    }

    toStringDate(date: DateTemplate) {
        return `${date.year}-${date.month}-${date.day}`
    }

    async handleSubmitTimelineJoinLeave(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        if (
            this.state.timelineJoinLeaveStartDateYear !== undefined &&
            this.state.timelineJoinLeaveStartDateMonth !== undefined &&
            this.state.timelineJoinLeaveStartDateDay !== undefined
        ) {
            await this.setState({
                timelineJoinLeaveStartDateInclusive: {
                    year: this.state.timelineJoinLeaveStartDateYear,
                    month: this.state.timelineJoinLeaveStartDateMonth,
                    day: this.state.timelineJoinLeaveStartDateDay
                }
            })
        } else {
            await this.setState({ timelineJoinLeaveStartDateInclusive: null })
        }

        if (
            this.state.timelineJoinLeaveEndDateYear !== undefined &&
            this.state.timelineJoinLeaveEndDateMonth !== undefined &&
            this.state.timelineJoinLeaveEndDateDay !== undefined
        ) {
            await this.setState({
                timelineJoinLeaveEndDateInclusive: {
                    year: this.state.timelineJoinLeaveEndDateYear,
                    month: this.state.timelineJoinLeaveEndDateMonth,
                    day: this.state.timelineJoinLeaveEndDateDay
                }
            })
        } else {
            await this.setState({ timelineJoinLeaveEndDateInclusive: null })
        }

        await this.setState({ timelineJoinLeaveId: Math.random() })
    }

    public render() {
        return <div>
            <CustomModule<ItemWithMessage<MemberJoinLeaveReport> | Message, Array<ChartModel>>
                id={this.state.timelineJoinLeaveId}
                user={this.props.user}
                title="Timeline Join Leave"
                description="Gets all joined and left Member ids, including differences and total counts at that time. (both `start` and `end` are inclusive dates and optional)"
                requestMethod={RequestMethod.POST}
                permissionsInfo={[Info.STATISTICS]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                call={() => statisticsTimelineJoinLeave(this.state.timelineJoinLeaveStartDateInclusive, this.state.timelineJoinLeaveEndDateInclusive)}
                map={(data) => {
                    if (isMessage(data)) {
                        throw new Error(`Error: ${data.message}`)
                    } else if (data.message.successful && data.item !== null) {
                        const stats = data.item.stats
                        return stats.map((item) => {
                            return {
                                name: this.toStringDate(item.time),
                                count: item.count,
                                joined: item.joined.length,
                                left: item.left.length
                            }
                        })
                    } else {
                        return undefined
                    }
                }}
                component={(data) =>
                    <div>
                        <LineChart
                            width={1200}
                            height={500}
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="left" stroke="red" />
                            <Line type="monotone" dataKey="joined" stroke="#82ca9d" />
                            <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </div>
                }
            />

            <form
                className="form"
                method="post"
                onSubmit={this.handleSubmitTimelineJoinLeave}>
                <div>
                    <p>Start:</p>
                    <input
                        className={this.state.timelineJoinLeaveStartDateYear !== undefined
                            ? (Number.isInteger(this.state.timelineJoinLeaveStartDateYear)
                                ? "form-input text-box-small valid"
                                : "form-input text-box-small not-valid"
                            ) : "form-input text-box-small"
                        }
                        type="number"
                        placeholder="year"
                        autoComplete="off"
                        value={this.state.timelineJoinLeaveStartDateYear}
                        onChange={event => this.setState({ timelineJoinLeaveStartDateYear: toNumberOrUndefined(parseInt(event.target.value)) })} />
                    <input
                        className={this.state.timelineJoinLeaveStartDateMonth !== undefined
                            ? (Number.isInteger(this.state.timelineJoinLeaveStartDateMonth)
                                ? "form-input text-box-small valid"
                                : "form-input text-box-small not-valid"
                            ) : "form-input text-box-small"
                        }
                        type="number"
                        placeholder="month"
                        autoComplete="off"
                        value={this.state.timelineJoinLeaveStartDateMonth}
                        onChange={event => this.setState({ timelineJoinLeaveStartDateMonth: toNumberOrUndefined(parseInt(event.target.value)) })} />
                    <input
                        className={this.state.timelineJoinLeaveStartDateDay !== undefined
                            ? (Number.isInteger(this.state.timelineJoinLeaveStartDateDay)
                                ? "form-input text-box-small valid"
                                : "form-input text-box-small not-valid"
                            ) : "form-input text-box-small"
                        }
                        type="number"
                        placeholder="day"
                        autoComplete="off"
                        value={this.state.timelineJoinLeaveStartDateDay}
                        onChange={event => this.setState({ timelineJoinLeaveStartDateDay: toNumberOrUndefined(parseInt(event.target.value)) })} />
                </div>
                <div>
                    <p>End:</p>
                    <input
                        className={this.state.timelineJoinLeaveEndDateYear !== undefined
                            ? (Number.isInteger(this.state.timelineJoinLeaveEndDateYear)
                                ? "form-input text-box-small valid"
                                : "form-input text-box-small not-valid"
                            ) : "form-input text-box-small"
                        }
                        type="number"
                        placeholder="year"
                        autoComplete="off"
                        value={this.state.timelineJoinLeaveEndDateYear}
                        onChange={event => this.setState({ timelineJoinLeaveEndDateYear: toNumberOrUndefined(parseInt(event.target.value)) })} />
                    <input
                        className={this.state.timelineJoinLeaveEndDateMonth !== undefined
                            ? (Number.isInteger(this.state.timelineJoinLeaveEndDateMonth)
                                ? "form-input text-box-small valid"
                                : "form-input text-box-small not-valid"
                            ) : "form-input text-box-small"
                        }
                        type="number"
                        placeholder="month"
                        autoComplete="off"
                        value={this.state.timelineJoinLeaveEndDateMonth}
                        onChange={event => this.setState({ timelineJoinLeaveEndDateMonth: toNumberOrUndefined(parseInt(event.target.value)) })} />
                    <input
                        className={this.state.timelineJoinLeaveEndDateDay !== undefined
                            ? (Number.isInteger(this.state.timelineJoinLeaveEndDateDay)
                                ? "form-input text-box-small valid"
                                : "form-input text-box-small not-valid"
                            ) : "form-input text-box-small"
                        }
                        type="number"
                        placeholder="day"
                        autoComplete="off"
                        value={this.state.timelineJoinLeaveEndDateDay}
                        onChange={event => this.setState({ timelineJoinLeaveEndDateDay: toNumberOrUndefined(parseInt(event.target.value)) })} />
                </div>
                <input
                    className="form-input form-submit-button form-submit-button-small"
                    type="submit"
                    value="Send" />
            </form>
        </div>
    }
}

interface ChartModel {
    name: string
    count: number
    joined: number
    left: number
}