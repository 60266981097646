import React from 'react'
import { UserLoggedIn } from '../ApiModels'

import './AuthModule.css'
import '../Form.css'
import { RequestMethod, Info, Create, Read, Update, Delete } from '../Global';

interface Props<A, B> {
    id: number | undefined
    user: UserLoggedIn
    title: string
    description: string
    requestMethod: RequestMethod
    permissionsInfo: Array<Info>
    permissionsCreate: Array<Create>
    permissionsRead: Array<Read>
    permissionsUpdate: Array<Update>
    permissionsDelete: Array<Delete>
    call: () => Promise<A>
    map: (data: A) => B | undefined
    component: (content: B) => JSX.Element | undefined
}

interface State {
    id: number | undefined
    content: JSX.Element | undefined
}

export default class CustomModule<A, B> extends React.Component<Props<A, B>, State> {
    constructor(props: Props<A, B>) {
        super(props)
        this.state = {
            id: this.props.id,
            content: undefined
        }

        this.update = this.update.bind(this)
    }

    async componentDidMount() {
        if (this.props.user.role !== null) {
            const role = this.props.user.role

            this.authorized = this.props.permissionsInfo.map((item) => role.allowedInfo.includes(item)).every((item) => item) &&
                this.props.permissionsCreate.map((item) => role.allowedCreate.includes(item)).every((item) => item) &&
                this.props.permissionsRead.map((item) => role.allowedRead.includes(item)).every((item) => item) &&
                this.props.permissionsUpdate.map((item) => role.allowedUpdate.includes(item)).every((item) => item) &&
                this.props.permissionsDelete.map((item) => role.allowedDelete.includes(item)).every((item) => item)
            this.forceUpdate()
            this.update()
        }
    }

    async componentDidUpdate() {
        if (this.authorized && this.props.id !== this.state.id) {
            await this.setState({ id: this.props.id })
            this.update()
        }
    }

    async update() {
        const res = await this.props.call()
        const map = this.props.map(res)
        if (map !== undefined) {
            this.setState({
                content: this.props.component(map)
            })
        }
    }

    authorized: boolean = false
    opened: boolean = false
    sending: boolean = false
    response: object | undefined = undefined

    public render() {
        return !this.authorized
            ? <div className="module-header-text">
                <h3>{this.props.title}</h3>
                <p>{this.props.description}</p>
            </div>
            : <div className="module-header-text">
                <h3>{this.props.title}</h3>
                <p>{this.props.description}</p>
                <br />
                {this.state.content !== undefined ? this.state.content : <p>Loading...</p>}
            </div>
    }
}