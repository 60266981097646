import React from 'react'
import { Redirect } from 'react-router-dom'
import '../../Form.css'

import { memberReadCurrentLoggedInUser, memberUpdateFirstMiddleAndLastName, memberUpdateAddress, memberUpdateDateOfBirth, memberUpdateMail, memberUpdateSettings, memberUpdatePasswordOfCurrentLoggedInUser } from '../../Requests'
import { Member, MemberAdditionalInfo, Address, DateTemplate, MemberSettings, ItemWithMessage, Message } from '../../ApiModels'
import { isMessage, toNumberOrUndefined } from '../../Helper'

interface ProfileProps {
    loggedIn: boolean
}

interface ProfileState {
    firstName: string
    middleName: string
    lastName: string

    addressCity: string
    addressHouseNumber: number | undefined
    addressHouseNumberLetter: string | undefined
    addressHouseNumberAddition: string | undefined
    addressPostalCode: string
    addressStreetName: string

    studentNumber: string | undefined

    dateOfBirthDay: number | undefined
    dateOfBirthMonth: number | undefined
    dateOfBirthYear: number | undefined

    showExternal: boolean
    alumni: boolean

    mail: string
    password: string
    passwordRepeat: string

    loading: boolean
    loadingText: string
    errorText: string | null
    updating: boolean

    updatingName: boolean
    errorMessageName: string | null

    updatingAddress: boolean
    errorMessageAddress: string | null

    updatingDateOfBirth: boolean
    errorMessageDateOfBirth: string | null

    updatingMail: boolean
    errorMessageMail: string | null

    updatingPassword: boolean
    errorMessagePassword: string | null

    updatingSettings: boolean
    errorMessageSettings: string | null
}

export default class Profile extends React.Component<ProfileProps, ProfileState> {
    constructor(props: ProfileProps) {
        super(props)
        this.state = {
            firstName: "",
            middleName: "",
            lastName: "",

            addressCity: "",
            addressHouseNumber: undefined,
            addressHouseNumberLetter: undefined,
            addressHouseNumberAddition: undefined,
            addressPostalCode: "",
            addressStreetName: "",

            studentNumber: undefined,

            dateOfBirthDay: undefined,
            dateOfBirthMonth: undefined,
            dateOfBirthYear: undefined,

            showExternal: false,
            alumni: false,

            mail: "",
            password: "",
            passwordRepeat: "",

            loading: true,
            loadingText: "Laden...",
            errorText: null,
            updating: false,

            updatingName: false,
            errorMessageName: null,

            updatingAddress: false,
            errorMessageAddress: null,

            updatingDateOfBirth: false,
            errorMessageDateOfBirth: null,

            updatingMail: false,
            errorMessageMail: null,

            updatingPassword: false,
            errorMessagePassword: null,

            updatingSettings: false,
            errorMessageSettings: null
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitName = this.handleSubmitName.bind(this)
        this.handleSubmitAddress = this.handleSubmitAddress.bind(this)
        this.handleSubmitDateOfBirth = this.handleSubmitDateOfBirth.bind(this)
        this.handleSubmitMail = this.handleSubmitMail.bind(this)
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this)
        this.handleSubmitSettings = this.handleSubmitSettings.bind(this)
    }

    async componentDidMount() {
        if (this.props.loggedIn) {
            const response: ItemWithMessage<Member> | Message = await memberReadCurrentLoggedInUser()

            if (isMessage(response)) {
                this.setState({
                    loadingText: "",
                    errorText: response.message
                })
            } else if (response.item !== null) {
                const member: Member = response.item

                this.setState({
                    firstName: member.firstName,
                    middleName: member.middleName,
                    lastName: member.lastName,
                })

                const additionalInfo: MemberAdditionalInfo | null = member.additionalInfo
                if (additionalInfo !== null) {
                    this.setState({
                        mail: additionalInfo.mail,
                        studentNumber: additionalInfo.studentNumber !== null ? additionalInfo.studentNumber : undefined
                    })

                    const dateOfBirth: DateTemplate | null = additionalInfo.dateOfBirth
                    if (dateOfBirth !== null) {
                        this.setState({
                            dateOfBirthDay: dateOfBirth.day,
                            dateOfBirthMonth: dateOfBirth.month,
                            dateOfBirthYear: dateOfBirth.year
                        })
                    }

                    const address: Address | null = additionalInfo.address
                    if (address !== null) {
                        this.setState({
                            addressCity: address.city,
                            addressHouseNumber: address.houseNumber !== null ? address.houseNumber : undefined,
                            addressHouseNumberLetter: address.houseNumberLetter !== null ? address.houseNumberLetter : undefined,
                            addressHouseNumberAddition: address.houseNumberAddition !== null ? address.houseNumberAddition : undefined,
                            addressPostalCode: address.postalCode,
                            addressStreetName: address.streetName
                        })
                    }
                }

                const settings: MemberSettings | null = member.settings
                if (settings !== null) {
                    this.setState({
                        showExternal: settings.items.showExternal,
                        alumni: settings.items.alumni
                    })
                }

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loadingText: "",
                    errorText: response.message.message
                })
            }
        }
    }

    private async handleSubmit(
        setState: (updating: boolean) => void,
        update: () => Promise<Message | null>,
        setErrorMessage: (errorMessage: string | null) => void
    ) {
        if (!this.state.updating) {
            await this.setState({ updating: true })
            await setState(true)
            setErrorMessage(null)

            const message = await update()
            if (
                message !== null &&
                !message.successful &&
                message.message !== null
            ) {
                setErrorMessage(message.message)
            }
            this.setState({ updating: false })
            setState(false)
        }
    }

    private async handleSubmitName(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.handleSubmit(
            (updating) => this.setState({ updatingName: updating }),
            () => memberUpdateFirstMiddleAndLastName(
                this.state.firstName,
                this.state.middleName,
                this.state.lastName
            ),
            (errorMessage) => this.setState({ errorMessageName: errorMessage })
        )
    }

    private async handleSubmitAddress(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.handleSubmit(
            (updating) => this.setState({ updatingAddress: updating }),
            () => {
                if (this.state.addressHouseNumber === undefined) {
                    return Promise.resolve(null)
                }
                return memberUpdateAddress(
                    this.state.addressCity,
                    this.state.addressHouseNumber,
                    this.state.addressHouseNumberAddition !== undefined ? this.state.addressHouseNumberAddition : null,
                    this.state.addressHouseNumberLetter !== undefined ? this.state.addressHouseNumberLetter : null,
                    this.state.addressPostalCode,
                    this.state.addressStreetName
                )
            },
            (errorMessage) => this.setState({ errorMessageAddress: errorMessage })
        )
    }

    private async handleSubmitDateOfBirth(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.handleSubmit(
            (updating) => this.setState({ updatingDateOfBirth: updating }),
            () => {
                const date: DateTemplate | null = (
                    this.state.dateOfBirthYear !== undefined &&
                    this.state.dateOfBirthMonth !== undefined &&
                    this.state.dateOfBirthDay !== undefined
                )
                    ? {
                        year: this.state.dateOfBirthYear,
                        month: this.state.dateOfBirthMonth,
                        day: this.state.dateOfBirthDay
                    }
                    : null
                return memberUpdateDateOfBirth(date)
            },
            (errorMessage) => this.setState({ errorMessageDateOfBirth: errorMessage })
        )
    }

    private async handleSubmitMail(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.handleSubmit(
            (updating) => this.setState({ updatingMail: updating }),
            () => memberUpdateMail(this.state.mail),
            (errorMessage) => this.setState({ errorMessageMail: errorMessage })
        )
    }

    private async handleSubmitPassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.handleSubmit(
            (updating) => this.setState({ updatingPassword: updating }),
            () => memberUpdatePasswordOfCurrentLoggedInUser(
                this.state.password,
                this.state.passwordRepeat
            ),
            (errorMessage) => this.setState({ errorMessagePassword: errorMessage })
        )
    }

    private async handleSubmitSettings(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.handleSubmit(
            (updating) => this.setState({ updatingSettings: updating }),
            () => memberUpdateSettings(
                this.state.showExternal,
                this.state.alumni
            ),
            (errorMessage) => this.setState({ errorMessageSettings: errorMessage })
        )
    }

    public render() {
        return !this.props.loggedIn ? (
            <Redirect to="/login" />
        ) : (
                <div className="form-container">
                    <div className="form-box-static">
                        {this.state.loading ? (
                            <div>
                                <h1>Mijn Profiel</h1>
                                <p>{this.state.loadingText}</p>
                                <div className="form-submit-error-text">
                                    {this.state.errorText !== null ? this.state.errorText : ""}
                                </div>
                            </div>
                        ) : (
                                <div>
                                    <h1>Mijn Profiel</h1>
                                    <h2>Verplichte gegevens</h2>
                                    <h3>Naam</h3>
                                    <form
                                        className="form"
                                        method="post"
                                        onSubmit={this.handleSubmitName}>
                                        <div>
                                            <input
                                                className={this.state.firstName !== ""
                                                    ? "form-input text-box-medium valid"
                                                    : "form-input text-box-medium"
                                                }
                                                type="text"
                                                placeholder="voornaam"
                                                autoComplete="off"
                                                value={this.state.firstName}
                                                onChange={event => this.setState({ firstName: event.target.value })}
                                                readOnly={this.state.updating}
                                                required />
                                            <input
                                                className={this.state.middleName !== ""
                                                    ? "form-input text-box-medium valid"
                                                    : "form-input text-box-medium"
                                                }
                                                type="text"
                                                placeholder="tussenvoegsel"
                                                autoComplete="off"
                                                value={this.state.middleName}
                                                onChange={event => this.setState({ middleName: event.target.value })}
                                                readOnly={this.state.updating} />
                                            <input
                                                className={this.state.lastName !== ""
                                                    ? "form-input text-box-medium valid"
                                                    : "form-input text-box-medium"
                                                }
                                                type="text"
                                                placeholder="achternaam"
                                                autoComplete="off"
                                                value={this.state.lastName}
                                                onChange={event => this.setState({ lastName: event.target.value })}
                                                readOnly={this.state.updating}
                                                required />
                                        </div>
                                        <div className="form-submit-error-text">
                                            {this.state.errorMessageName !== null ? this.state.errorMessageName : ""}
                                        </div>
                                        <input
                                            className="form-input form-submit-button form-submit-button-small"
                                            type="submit"
                                            value={!this.state.updatingName ? "Wijzigen" : "Opslaan..."}
                                            readOnly={this.state.updating} />
                                    </form>

                                    <h3>Adres</h3>
                                    <form
                                        className="form"
                                        method="post"
                                        onSubmit={this.handleSubmitAddress}>
                                        <div>
                                            <input
                                                className={this.state.addressHouseNumber !== undefined
                                                    ? (Number.isInteger(this.state.addressHouseNumber)
                                                        ? "form-input text-box-small valid"
                                                        : "form-input text-box-small not-valid"
                                                    ) : "form-input text-box-small"
                                                }
                                                name="middleName"
                                                type="number"
                                                placeholder="huisnummer"
                                                autoComplete="off"
                                                value={this.state.addressHouseNumber}
                                                onChange={event => this.setState({ addressHouseNumber: toNumberOrUndefined(parseInt(event.target.value)) })}
                                                readOnly={this.state.updating}
                                                required />
                                            <input
                                                className={this.state.addressHouseNumberLetter !== undefined
                                                    ? "form-input text-box-extra-small valid"
                                                    : "form-input text-box-extra-small"
                                                }
                                                type="text"
                                                placeholder="letter"
                                                autoComplete="off"
                                                value={this.state.addressHouseNumberLetter}
                                                onChange={event => {
                                                    let addressHouseNumberLetter: string | undefined = event.target.value
                                                    if (addressHouseNumberLetter !== undefined && addressHouseNumberLetter.length === 0) {
                                                        addressHouseNumberLetter = undefined
                                                    }
                                                    this.setState({ addressHouseNumberLetter: addressHouseNumberLetter })
                                                }}
                                                readOnly={this.state.updating} />
                                            <input
                                                className={this.state.addressHouseNumberAddition !== undefined
                                                    ? "form-input text-box-extra-small valid"
                                                    : "form-input text-box-extra-small"
                                                }
                                                type="text"
                                                placeholder="toevoeging"
                                                autoComplete="off"
                                                value={this.state.addressHouseNumberAddition}
                                                onChange={event => {
                                                    let addressHouseNumberAddition: string | undefined = event.target.value
                                                    if (addressHouseNumberAddition !== undefined && addressHouseNumberAddition.length === 0) {
                                                        addressHouseNumberAddition = undefined
                                                    }
                                                    this.setState({ addressHouseNumberAddition: addressHouseNumberAddition })
                                                }}
                                                readOnly={this.state.updating} />
                                            <input
                                                className={this.state.addressPostalCode !== ""
                                                    ? "form-input text-box-small valid"
                                                    : "form-input text-box-small"
                                                }
                                                type="text"
                                                placeholder="postcode"
                                                autoComplete="off"
                                                value={this.state.addressPostalCode}
                                                onChange={event => {
                                                    const addressPostalCode = event.target.value
                                                        .replace(" ", "")
                                                        .toUpperCase()
                                                    this.setState({ addressPostalCode: addressPostalCode })
                                                }}
                                                readOnly={this.state.updating}
                                                required />
                                        </div>
                                        <div>
                                            <input
                                                className={this.state.addressStreetName !== ""
                                                    ? "form-input text-box-large valid"
                                                    : "form-input text-box-large"
                                                }
                                                type="text"
                                                placeholder="straatnaam"
                                                autoComplete="off"
                                                value={this.state.addressStreetName}
                                                onChange={event => this.setState({ addressStreetName: event.target.value })}
                                                readOnly={this.state.updating}
                                                required />
                                            <input
                                                className={this.state.addressCity !== ""
                                                    ? "form-input text-box-medium valid"
                                                    : "form-input text-box-medium"
                                                }
                                                type="text"
                                                placeholder="stad"
                                                autoComplete="off"
                                                value={this.state.addressCity}
                                                onChange={event => this.setState({ addressCity: event.target.value })}
                                                readOnly={this.state.updating}
                                                required />
                                        </div>
                                        <div className="form-submit-error-text">
                                            {this.state.errorMessageAddress !== null ? this.state.errorMessageAddress : ""}
                                        </div>
                                        <input
                                            className="form-input form-submit-button form-submit-button-small"
                                            type="submit"
                                            value={!this.state.updatingAddress ? "Wijzigen" : "Opslaan..."}
                                            readOnly={this.state.updating} />
                                    </form>

                                    <h3>Studentnummer</h3>
                                    <div>
                                        <input
                                            className={"form-input text-box-medium"}
                                            type="text"
                                            placeholder="studentnummer"
                                            autoComplete="off"
                                            value={this.state.studentNumber}
                                            readOnly={true} />
                                    </div>

                                    <h2>Optionele gegevens</h2>
                                    <h3>Geboortedatum</h3>
                                    <form
                                        className="form"
                                        method="post"
                                        onSubmit={this.handleSubmitDateOfBirth}>
                                        <div>
                                            <input
                                                className={this.state.dateOfBirthDay !== undefined
                                                    ? (Number.isInteger(this.state.dateOfBirthDay)
                                                        ? "form-input text-box-small valid"
                                                        : "form-input text-box-small not-valid"
                                                    ) : "form-input text-box-small"
                                                }
                                                type="number"
                                                placeholder="dag"
                                                autoComplete="off"
                                                value={this.state.dateOfBirthDay}
                                                onChange={event => this.setState({ dateOfBirthDay: toNumberOrUndefined(parseInt(event.target.value)) })}
                                                readOnly={this.state.updating} />
                                            <input
                                                className={this.state.dateOfBirthMonth !== undefined
                                                    ? (Number.isInteger(this.state.dateOfBirthMonth)
                                                        ? "form-input text-box-small valid"
                                                        : "form-input text-box-small not-valid"
                                                    ) : "form-input text-box-small"
                                                }
                                                type="number"
                                                placeholder="maand"
                                                autoComplete="off"
                                                value={this.state.dateOfBirthMonth}
                                                onChange={event => this.setState({ dateOfBirthMonth: toNumberOrUndefined(parseInt(event.target.value)) })}
                                                readOnly={this.state.updating} />
                                            <input
                                                className={this.state.dateOfBirthYear !== undefined
                                                    ? (Number.isInteger(this.state.dateOfBirthYear)
                                                        ? "form-input text-box-small valid"
                                                        : "form-input text-box-small not-valid"
                                                    ) : "form-input text-box-small"
                                                }
                                                type="number"
                                                placeholder="jaar"
                                                autoComplete="off"
                                                value={this.state.dateOfBirthYear}
                                                onChange={event => this.setState({ dateOfBirthYear: toNumberOrUndefined(parseInt(event.target.value)) })}
                                                readOnly={this.state.updating} />
                                        </div>
                                        <div className="form-submit-error-text">
                                            {this.state.errorMessageDateOfBirth !== null ? this.state.errorMessageDateOfBirth : ""}
                                        </div>
                                        <input
                                            className="form-input form-submit-button form-submit-button-small"
                                            type="submit"
                                            value={!this.state.updatingDateOfBirth ? "Wijzigen" : "Opslaan..."}
                                            readOnly={this.state.updating} />
                                    </form>

                                    <h2>Inloggegevens</h2>
                                    <form
                                        className="form"
                                        method="post"
                                        onSubmit={this.handleSubmitMail}>
                                        <input
                                            className={this.state.mail !== ""
                                                ? "form-input text-box-large valid"
                                                : "form-input text-box-large"
                                            }
                                            type="text"
                                            placeholder="email"
                                            autoComplete="off"
                                            value={this.state.mail}
                                            onChange={event => this.setState({ mail: event.target.value })}
                                            readOnly={this.state.updating}
                                            required />
                                        <div className="form-submit-error-text">
                                            {this.state.errorMessageMail !== null ? this.state.errorMessageMail : ""}
                                        </div>
                                        <input
                                            className="form-input form-submit-button form-submit-button-small"
                                            type="submit"
                                            value={!this.state.updatingMail ? "Wijzigen" : "Opslaan..."}
                                            readOnly={this.state.updating} />
                                    </form>

                                    <form
                                        className="form"
                                        method="post"
                                        onSubmit={this.handleSubmitPassword}>
                                        <input
                                            className={this.state.password !== ""
                                                ? "form-input text-box-large valid"
                                                : "form-input text-box-large"
                                            }
                                            type="password"
                                            placeholder="wachtwoord"
                                            autoComplete="off"
                                            value={this.state.password}
                                            onChange={event => this.setState({ password: event.target.value })}
                                            readOnly={this.state.updating}
                                            required />
                                        <input
                                            className={this.state.passwordRepeat !== ""
                                                ? (this.state.password === this.state.passwordRepeat
                                                    ? "form-input text-box-large valid"
                                                    : "form-input text-box-large not-valid"
                                                )
                                                : "form-input text-box-large"
                                            }
                                            type="password"
                                            placeholder="herhaal wachtwoord"
                                            autoComplete="off"
                                            value={this.state.passwordRepeat}
                                            onChange={event => this.setState({ passwordRepeat: event.target.value })}
                                            readOnly={this.state.updating}
                                            required />
                                        <div className="form-submit-error-text">
                                            {this.state.errorMessagePassword !== null ? this.state.errorMessagePassword : ""}
                                        </div>
                                        <input
                                            className="form-input form-submit-button form-submit-button-small"
                                            type="submit"
                                            value={!this.state.updatingPassword ? "Wijzigen" : "Opslaan..."}
                                            readOnly={this.state.updating} />
                                    </form>

                                    <form
                                        className="form"
                                        method="post"
                                        onSubmit={this.handleSubmitSettings}>

                                        <h2>Toestemmingen</h2>
                                        <div className="form-input-box">
                                            <input
                                                className="form-input form-input-checkbox"
                                                type="checkbox"
                                                checked={this.state.showExternal}
                                                onChange={event => this.setState({ showExternal: event.target.checked })}
                                                readOnly={this.state.updating} />
                                            <b>Extern zichtbaar profiel (optioneel);</b> <i className="text-size-small">hiermee kan je je profiel openlijk zichtbaar stellen voor anderen. Zo kan je je betrokkenheid bij de studievereniging laten zien en bijvoorbeeld toevoegen aan je CV.</i>
                                        </div>

                                        <h2>Instellingen</h2>
                                        <div className="form-input-box">
                                            <input
                                                className="form-input form-input-checkbox"
                                                type="checkbox"
                                                checked={this.state.alumni}
                                                onChange={event => this.setState({ alumni: event.target.checked })}
                                                readOnly={this.state.updating} />
                                            <b>Ik ben afgestudeerd;</b> <i className="text-size-small">hiermee geef je aan dat je afgestudeerd bent. (Hierdoor zal je specifieker/minder gecontacteerd worden dan studenten die nog studeren)</i>
                                        </div>

                                        <div className="form-submit-error-text">
                                            {this.state.errorMessageSettings !== null ? this.state.errorMessageSettings : ""}
                                        </div>
                                        <input
                                            className="form-input form-submit-button form-submit-button-small"
                                            type="submit"
                                            value={!this.state.updatingSettings ? "Wijzigen" : "Opslaan..."}
                                            readOnly={this.state.updating} />
                                    </form>
                                </div>
                            )
                        }
                    </div>
                </div>
            )
    }
}