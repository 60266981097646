import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import '../../Form.css'

import { MemberTemplateContent } from '../../ApiModels'
import Register from './Register'
import { memberTemplateRead } from '../../Requests'
import { isMessage } from '../../Helper'

interface RegisterTemplateState {
    id: string
    studentNumber: string
    checking: boolean
    errorMessage: string | null

    memberTemplate: MemberTemplateContent | null
}

export default class RegisterTemplate extends React.Component<RouteComponentProps<any>, RegisterTemplateState> {
    constructor(props: RouteComponentProps<any>) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            studentNumber: "",
            checking: false,
            errorMessage: null,

            memberTemplate: null
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        if (!this.state.checking) {
            this.setState({
                checking: true,
                errorMessage: null
            })

            const response = await memberTemplateRead(this.state.id, this.state.studentNumber)

            if (!isMessage(response)) {
                this.setState({
                    checking: false,
                    memberTemplate: response
                })
            } else {
                this.setState({
                    checking: false,
                    errorMessage: "Studentnumber incorrect for requested member template"
                })
            }
        }
    }

    public render() {
        return this.state.memberTemplate === null
            ? (
                <div className="form-container">
                    <div className="form-box-static">
                        <form
                            className="form"
                            method="post"
                            onSubmit={this.handleSubmit}>

                            <h1>Aanmelden</h1>
                            <h2>Studentnummer</h2>
                            <input
                                className="form-input text-box-medium"
                                type="text"
                                placeholder="studentnummer"
                                autoComplete="off"
                                value={this.state.studentNumber}
                                onChange={event => this.setState({ studentNumber: event.target.value })}
                                readOnly={this.state.checking}
                                required />

                            <div className="form-submit-error-text">
                                {this.state.errorMessage !== null ? this.state.errorMessage : ""}
                            </div>

                            <input
                                className="form-input form-submit-button form-submit-button-small"
                                type="submit"
                                value={!this.state.checking ? "Gegevens ophalen" : "Gegevens ophalen..."}
                                readOnly={this.state.checking} />
                        </form>
                    </div>
                </div>
            )
            : (<Register memberTemplate={{ id: this.state.id, memberTemplate: this.state.memberTemplate }} />)
    }
}