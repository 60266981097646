import React from 'react'
import { UserLoggedIn } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { memberReadByStudentNumber, memberReadById, memberReadByMail, memberUpdateStudentNumber, memberUpdatePassword, memberUpdateSetActive, memberUpdateSetInactive } from '../../Requests';
import { Read, Update, RequestMethod } from '../../Global';

interface MemberProps {
    user: UserLoggedIn
}

interface MemberState {
}

export default class Member extends React.Component<MemberProps, MemberState> {
    constructor(props: MemberProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Read */}
            <RequestModule<MemberReadInputState>
                user={this.props.user}
                title="Read Member by student number"
                path="/member/read/studentNumber"
                requestMethod={RequestMethod.POST}
                state={{
                    input: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[Read.MEMBER_STUDENT_NUMBER]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "studentNumber",
                        type: "text",
                        getter: (thiz: RequestModule<MemberReadInputState>) => thiz.state.input,
                        setter: (v: any, thiz: RequestModule<MemberReadInputState>) => thiz.setState({ input: v })
                    }
                ]}
                submit={(state) => memberReadByStudentNumber(state.input)}
            />

            <RequestModule<MemberReadInputState>
                user={this.props.user}
                title="Read Member by mail"
                path="/member/read/mail"
                requestMethod={RequestMethod.POST}
                state={{
                    input: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[Read.MEMBER_MAIL]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "mail",
                        type: "text",
                        getter: (thiz: RequestModule<MemberReadInputState>) => thiz.state.input,
                        setter: (v: any, thiz: RequestModule<MemberReadInputState>) => thiz.setState({ input: v })
                    }
                ]}
                submit={(state) => memberReadByMail(state.input)}
            />

            <RequestModule<MemberReadInputState>
                user={this.props.user}
                title="Read Member by id"
                path="/member/read"
                requestMethod={RequestMethod.POST}
                state={{
                    input: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[Read.MEMBER]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberReadInputState>) => thiz.state.input,
                        setter: (v: any, thiz: RequestModule<MemberReadInputState>) => thiz.setState({ input: v })
                    }
                ]}
                submit={(state) => memberReadById(state.input)}
            />

            {/* Update */}
            <RequestModule<MemberUpdateStudentNumberState>
                user={this.props.user}
                title="Update Member student number"
                path="/member/update/studentNumber"
                requestMethod={RequestMethod.POST}
                state={{
                    memberId: "",
                    studentNumber: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_STUDENT_NUMBER]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberUpdateStudentNumberState>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<MemberUpdateStudentNumberState>) => thiz.setState({ memberId: v })
                    },
                    {
                        key: "studentNumber",
                        type: "text",
                        getter: (thiz: RequestModule<MemberUpdateStudentNumberState>) => thiz.state.studentNumber,
                        setter: (v: any, thiz: RequestModule<MemberUpdateStudentNumberState>) => thiz.setState({ studentNumber: v })
                    }
                ]}
                submit={(state) => {
                    const studentNumber = state.studentNumber.length > 0 ? state.studentNumber : null
                    return memberUpdateStudentNumber(
                        state.memberId,
                        studentNumber
                    )
                }}
            />

            <RequestModule<MemberUpdateInputState>
                user={this.props.user}
                title="Update Member set active"
                path="/member/update/setActive"
                requestMethod={RequestMethod.PATCH}
                state={{
                    memberId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_ACTIVE]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberUpdateInputState>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<MemberUpdateInputState>) => thiz.setState({ memberId: v })
                    }
                ]}
                submit={(state) => memberUpdateSetActive(state.memberId)}
            />

            <RequestModule<MemberUpdateInputState>
                user={this.props.user}
                title="Update Member set inactive"
                path="/member/update/setInactive"
                requestMethod={RequestMethod.PATCH}
                state={{
                    memberId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_ACTIVE]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberUpdateInputState>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<MemberUpdateInputState>) => thiz.setState({ memberId: v })
                    }
                ]}
                submit={(state) => memberUpdateSetInactive(state.memberId)}
            />

            <RequestModule<MemberUpdatePasswordState>
                user={this.props.user}
                title="Update Member password"
                path="/member/update/password"
                requestMethod={RequestMethod.PATCH}
                state={{
                    memberId: "",
                    password: "",
                    passwordRepeat: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_PASSWORD]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberUpdatePasswordState>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<MemberUpdatePasswordState>) => thiz.setState({ memberId: v })
                    },
                    {
                        key: "password",
                        type: "password",
                        getter: (thiz: RequestModule<MemberUpdatePasswordState>) => thiz.state.password,
                        setter: (v: any, thiz: RequestModule<MemberUpdatePasswordState>) => thiz.setState({ password: v })
                    },
                    {
                        key: "passwordRepeat",
                        type: "password",
                        getter: (thiz: RequestModule<MemberUpdatePasswordState>) => thiz.state.passwordRepeat,
                        setter: (v: any, thiz: RequestModule<MemberUpdatePasswordState>) => thiz.setState({ passwordRepeat: v })
                    }
                ]}
                submit={(state) => memberUpdatePassword(
                    state.memberId,
                    state.password,
                    state.passwordRepeat
                )}
            />
        </div>
    }
}

interface MemberReadInputState {
    input: string
}

interface MemberUpdateInputState {
    memberId: string
}

interface MemberUpdateStudentNumberState {
    memberId: string
    studentNumber: string
}

interface MemberUpdatePasswordState {
    memberId: string
    password: string
    passwordRepeat: string
}