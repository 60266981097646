import React from 'react'
import '../Form.css'
import "./Contact.css"
import { SendContactRecaptchaCreateInput } from "../ApiModels";
import { sendContactRecaptcha } from "../Requests";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from '../Global';

interface contactState {
	name: string
	mail: string
	org: string
	phone: string
	subject: string
	content: string
	recaptchaResponse: string

	errorMessage: string | undefined
	message: string | undefined
	enabled: boolean
}

export default class Contact extends React.Component<{}, contactState>{
	constructor(props: any) {
		super(props)
		this.state = {
			name: "",
			mail: "",
			org: "",
			phone: "",
			subject: "",
			content: "",
			recaptchaResponse: "",

			errorMessage: undefined,
			message: undefined,
			enabled: true
		};

		this.handleSubmit = this.handleSubmit.bind(this)
	}
	recaptcha: ReCAPTCHA | null = null;

	private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()

		this.setState({
			errorMessage: undefined,
			message: undefined
		})

		const contactRecaptcha: SendContactRecaptchaCreateInput = {
			response: this.state.recaptchaResponse,
			input: {
				name: this.state.name,
				org: this.state.org !== undefined ? this.state.org : null,
				phone: this.state.phone !== undefined ? this.state.phone : null,
				mail: this.state.mail,
				subject: this.state.subject,
				content: this.state.content,
			}
		}

		const contactResponse = await sendContactRecaptcha(contactRecaptcha)

		if (contactResponse.successful) {
			this.setState({
				message: "Mail succesvol verstuurd, we komen zo snel mogelijk bij je terug!",
				enabled: false
			})
		} else {
			this.resetRecaptcha()
			this.setState({
				errorMessage: "Er ging iets fout, probeer het opnieuw..."
			})
		}
	}

	private resetRecaptcha() {
		if (this.recaptcha !== null) {
			this.recaptcha.reset()
		}
	}

	public render() {
		return (
			<div className="form-container">
				<div className="form-box-static">
					<h1>Contact</h1>
					<h2>Computer Science Association Rotterdam</h2>
					KvK: 74532871<br />
					Wijnhaven 107 (WD.04.022)<br />
					3011WN Rotterdam<br />
					<br />
					{this.state.enabled
						? (
							<form
								className="form contact-form"
								method="post"
								onSubmit={this.handleSubmit}>

								<input className="form-input contact"
									name="naam"
									type="text"
									placeholder="Naam*"
									autoComplete="off"
									onChange={event => this.setState({ name: event.target.value })}
									required />
								<input className="form-input contact"
									name="email"
									type="email"
									placeholder="Email*"
									autoComplete="off"
									onChange={event => this.setState({ mail: event.target.value })}
									required />
								<input className="form-input contact"
									name="Organisatie"
									type="text"
									placeholder="Organisatie"
									autoComplete="off"
									onChange={event => this.setState({ org: event.target.value })}
								/>
								<input className="form-input contact"
									name="Telefoon"
									type="tel"
									placeholder="Telefoon"
									autoComplete="off"
									onChange={event => this.setState({ phone: event.target.value })}
								/>
								<br />
								<input className="form-input full-length-contact"
									name="Onderwerp"
									type="text"
									placeholder="Onderwerp*"
									autoComplete="off"
									onChange={event => this.setState({ subject: event.target.value })}
									required
								/>
								<br />
								<textarea id="bericht" className="form-input full-length-contact"
									name="Bericht"
									placeholder="Bericht*"
									autoComplete="off"
									onChange={event => this.setState({ content: event.target.value })}
									required
								></textarea>
								<br />
								<div className="g-recaptcha">
									<ReCAPTCHA
										ref={(r) => this.recaptcha = r}
										sitekey={RECAPTCHA_SITEKEY}
										onChange={(e: any) => this.setState({ recaptchaResponse: e })}
									/>
								</div>

								<div className="form-submit-error-text">
									{this.state.errorMessage !== null ? this.state.errorMessage : ""}
								</div>

								<input
									className="form-input form-submit-button"
									type="submit"
									value="Versturen"
								/>
							</form>
						) : undefined}

					<div className="text-size-small">
						{this.state.message !== null ? this.state.message : ""}
					</div>
				</div>
			</div>
		)
	}
}
