import React from 'react'
import { UserLoggedIn, MemberCommitteeCreateInput, MemberCommitteeBoardUpdateInput, MemberCommitteeTagUpdateInput, MemberCommitteeImageUrlUpdateInput, MemberCommitteeShortDescriptionUpdateInput, MemberCommitteeLongDescriptionUpdateInput } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { memberCommitteeCreate, memberCommitteeUpdateBoardAdd, memberCommitteeUpdateBoardRemove, memberCommitteeUpdateChairmanTagAdd, memberCommitteeUpdateChairmanTagRemove, memberCommitteeUpdateCommitteeTagAdd, memberCommitteeUpdateCommitteeTagRemove, memberCommitteeUpdateImageUrl, memberCommitteeUpdateShortDescription, memberCommitteeUpdateLongDescription, memberCommitteeDelete } from '../../Requests';
import { Create, RequestMethod, Update, Delete } from '../../Global';

interface MemberCommitteeProps {
    user: UserLoggedIn
}

interface MemberCommitteeState {
}

export default class MemberCommittee extends React.Component<MemberCommitteeProps, MemberCommitteeState> {
    constructor(props: MemberCommitteeProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Create */}
            <RequestModule<MemberCommitteeCreateInput>
                user={this.props.user}
                title="Add Member Committee information to Member"
                path="/memberCommittee/create"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    imgUrl: null,
                    shortDescription: null,
                    longDescription: null
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.MEMBER_COMMITTEE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeCreateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeCreateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "imgUrl",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeCreateInput>) => thiz.state.imgUrl,
                        setter: (v: string, thiz: RequestModule<MemberCommitteeCreateInput>) => thiz.setState({ imgUrl: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "shortDescription",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeCreateInput>) => thiz.state.shortDescription,
                        setter: (v: string, thiz: RequestModule<MemberCommitteeCreateInput>) => thiz.setState({ shortDescription: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "longDescription",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeCreateInput>) => thiz.state.longDescription,
                        setter: (v: string, thiz: RequestModule<MemberCommitteeCreateInput>) => thiz.setState({ longDescription: (v.replace(" ", "").length > 0) ? v : null })
                    }
                ]}
                submit={(state) => memberCommitteeCreate(state)}
            />

            {/* Update */}
            <RequestModule<MemberCommitteeBoardUpdateInput>
                user={this.props.user}
                title="Add Member to Board"
                path="/memberCommittee/update/board/add"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    type: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_BOARD]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeBoardUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeBoardUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "type",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeBoardUpdateInput>) => thiz.state.type,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeBoardUpdateInput>) => thiz.setState({ type: v })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateBoardAdd(state)}
            />

            <RequestModule<MemberCommitteeBoardUpdateInput>
                user={this.props.user}
                title="Remove Member from Board"
                path="/memberCommittee/update/board/remove"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    type: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_BOARD]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeBoardUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeBoardUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "type",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeBoardUpdateInput>) => thiz.state.type,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeBoardUpdateInput>) => thiz.setState({ type: v })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateBoardRemove(state)}
            />

            <RequestModule<MemberCommitteeTagUpdateInput>
                user={this.props.user}
                title="Add Committee Chairman tag to Member"
                path="/memberCommittee/update/chairmanTag/add"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_COMMITTEE_COMMITTEE_CHAIRMAN_TAG]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateChairmanTagAdd(state)}
            />

            <RequestModule<MemberCommitteeTagUpdateInput>
                user={this.props.user}
                title="Remove Committee Chairman tag from Member"
                path="/memberCommittee/update/chairmanTag/remove"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_COMMITTEE_COMMITTEE_CHAIRMAN_TAG]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateChairmanTagRemove(state)}
            />

            <RequestModule<MemberCommitteeTagUpdateInput>
                user={this.props.user}
                title="Add Committee tag to Member"
                path="/memberCommittee/update/committeeTag/add"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_COMMITTEE_COMMITTEE_TAG]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateCommitteeTagAdd(state)}
            />

            <RequestModule<MemberCommitteeTagUpdateInput>
                user={this.props.user}
                title="Remove Committee tag from Member"
                path="/memberCommittee/update/committeeTag/remove"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_COMMITTEE_COMMITTEE_TAG]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeTagUpdateInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateCommitteeTagRemove(state)}
            />

            <RequestModule<MemberCommitteeImageUrlUpdateInput>
                user={this.props.user}
                title="Update image url of Member"
                path="/memberCommittee/update/imageUrl"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    imageUrl: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_COMMITTEE_IMAGE_URL]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeImageUrlUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeImageUrlUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "imageUrl",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeImageUrlUpdateInput>) => thiz.state.imageUrl,
                        setter: (v: string, thiz: RequestModule<MemberCommitteeImageUrlUpdateInput>) => thiz.setState({ imageUrl: (v.replace(" ", "").length > 0) ? v : null })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateImageUrl(state)}
            />

            <RequestModule<MemberCommitteeShortDescriptionUpdateInput>
                user={this.props.user}
                title="Update short description of Member"
                path="/memberCommittee/update/shortDescription"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    shortDescription: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_COMMITTEE_SHORT_DESCRIPTION]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeShortDescriptionUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeShortDescriptionUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "shortDescription",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeShortDescriptionUpdateInput>) => thiz.state.shortDescription,
                        setter: (v: string, thiz: RequestModule<MemberCommitteeShortDescriptionUpdateInput>) => thiz.setState({ shortDescription: (v.replace(" ", "").length > 0) ? v : null })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateShortDescription(state)}
            />

            <RequestModule<MemberCommitteeLongDescriptionUpdateInput>
                user={this.props.user}
                title="Update long description of Member"
                path="/memberCommittee/update/longDescription"
                requestMethod={RequestMethod.PATCH}
                state={{
                    id: "",
                    longDescription: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.MEMBER_COMMITTEE_LONG_DESCRIPTION]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeLongDescriptionUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeLongDescriptionUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "longDescription",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeLongDescriptionUpdateInput>) => thiz.state.longDescription,
                        setter: (v: string, thiz: RequestModule<MemberCommitteeLongDescriptionUpdateInput>) => thiz.setState({ longDescription: (v.replace(" ", "").length > 0) ? v : null })
                    }
                ]}
                submit={(state) => memberCommitteeUpdateLongDescription(state)}
            />

            {/* Delete */}

            <RequestModule<MemberCommitteeDeleteInput>
                user={this.props.user}
                title="Remove Member Committee information from Member"
                path="/memberCommittee/delete"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.MEMBER_COMMITTEE]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<MemberCommitteeDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<MemberCommitteeDeleteInput>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => memberCommitteeDelete(state.id)}
            />
        </div>
    }
}

interface MemberCommitteeDeleteInput {
    id: string
}