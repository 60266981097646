import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import './index.css'

import {checkAuthIsValid} from './components/Persistence'

import Header from './components/header/Header'
import Footer from './components/footer/Footer'

import Home from './components/home/Home'
import Login from './components/auth/login/Login'
import Logout from './components/auth/logout/Logout'
import Register from './components/auth/register/Register'
import Profile from './components/member/profile/Profile'
import RegisterTemplate from './components/auth/register/RegisterTemplate'

import Contact from './components/contact/Contact'

import Admin from './components/admin/Admin'
import {UserLoggedIn} from './components/ApiModels';

interface AppState {
    user: UserLoggedIn
}

export default class App extends React.Component<{}, AppState> {
    constructor(props: any) {
        super(props)
        this.state = {
            user: {
                loggedIn: false,
                role: null
            }
        }
    }

    componentDidMount() {
        this.updateAuth()
    }

    private updateAuth = async () => {
        this.setState({user: await checkAuthIsValid()})
    }

    public render() {
        return (
            <BrowserRouter>
                <div className="page-container">
                    <Header user={this.state.user}/>

                    <div className="content-wrap">
                        <Switch>
                            <Route path="/" component={Home} exact/>

                            <Route path="/login" exact>
                                <Login updateAuth={this.updateAuth}/>
                            </Route>
                            <Route path="/logout" exact>
                                <Logout updateAuth={this.updateAuth}/>
                            </Route>
                            <Route path="/register" component={Register} exact/>
                            <Route path="/register/template/:id" component={RegisterTemplate} exact/>

                            <Route path="/profile" exact>
                                <Profile loggedIn={this.state.user.loggedIn}/>
                            </Route>

                            <Route path="/contact" component={Contact} exact/>
                            <Route path="/admin" exact>
                                <Admin user={this.state.user}/>
                            </Route>
                        </Switch>
                    </div>

                    <Footer/>
                </div>
            </BrowserRouter>

        )
    }
}